import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGateway } from 'src/app/domain/models/User/gateway/user-gateway';
import { User } from 'src/app/domain/models/User/user';
import { GenericService } from 'src/app/infraestructure/helpers/generic.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends UserGateway {

  private _url = 'http://localhost:4000';
  constructor(private genericService: GenericService) {super(); }

  override create(user: User): Observable<User> {
    return this.genericService.post<User>(this._url, 'user')
  }
  override update(id: string, update: any): Observable<User> {
    throw new Error('Method not implemented.');
  }
  getByID(id: string): Observable<User> {
    return this.genericService.get<User>(this._url,`user/${id}`)
  }
}
