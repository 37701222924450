<div class="womanCont" (mouseenter)="activateSound()" (mouseleave)="deactivateSound()">
    <div class="handCont">
        <div class="eyes">
            <img width="1920" height= "1080" alt="eyes" class="img-fluid" src="/assets/images/hero/woman/eyes.svg" loading="lazy">
        </div>
        <div class="hand">
            <img width="1920" height= "1080" alt="hand" class="img-fluid" src="/assets/images/hero/woman/hand.svg" loading="lazy">
        </div>
    </div>

    <div class="body">
        <div class="womandHead">
            <img width="1920" height= "1080" alt="womanHead"  class="img-fluid" src="/assets/images/hero/woman/headWoman.svg" loading="lazy">

        </div>
        <div class="bodyImg">
            <img width="1920" height= "1080" alt="womanBody" class="img-fluid" src="/assets/images/hero/woman/body.svg" loading="lazy">

        </div>
    </div>

</div>