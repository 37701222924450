import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserUseCases } from 'src/app/domain/usecase/userusecase';
import { MailChimpSerivce } from 'src/app/infraestructure/driven-adapter/mailchimp/mailchimp.service';
import { LazyLoadService } from 'src/app/infraestructure/driven-adapter/services/lazy-load.service';
import { EyesComponent } from './eyes/eyes.component';
import { AngelComponent } from './angel/angel.component';
import { PapersComponent } from './papers/papers.component';
import { RollerComponent } from './roller/roller.component';
import { RobotComponent } from './robot/robot.component';
import { BusComponent } from './bus/bus.component';
import { PhoneComponent } from './phone/phone.component';
import { WomanComponent } from './woman/woman.component';
import { TvComponent } from './tv/tv.component';
import { CanComponent } from './can/can.component';
import { PencilComponent } from './pencil/pencil.component';
import { TurntableComponent } from './turntable/turntable.component';
import { MachineComponent } from './machine/machine.component';
import { ChairComponent } from './chair/chair.component';
import { SoundService } from 'src/app/infraestructure/driven-adapter/services/sound.service';
@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent {
  htmlText: string = '>';
  public startedPlay: boolean = false;
  public show: boolean = false;
  isMoving = false;
  mute: boolean = false;
  componentsToLoad: string[] = [];
  holographicElement: HTMLElement | null = null;
  @Output() navigate = new EventEmitter<number>();
  @Output() slideContact = new EventEmitter<number>();
  @ViewChild('eyesContainer', {read: ViewContainerRef}) eyesContainer!:ViewContainerRef;
  @ViewChild('angelContainer', {read: ViewContainerRef}) angelContainer!:ViewContainerRef;
  @ViewChild('papersContainer', {read: ViewContainerRef}) papersContainer!:ViewContainerRef;
  @ViewChild('rollerContainer', {read: ViewContainerRef}) rollerContainer!:ViewContainerRef;
  @ViewChild('robotContainer', {read: ViewContainerRef}) robotContainer!:ViewContainerRef;
  @ViewChild('busContainer', {read: ViewContainerRef}) busContainer!:ViewContainerRef;
  @ViewChild('phoneContainer', {read: ViewContainerRef}) phoneContainer!:ViewContainerRef;
  @ViewChild('womanContainer', {read: ViewContainerRef}) womanContainer!:ViewContainerRef;
  @ViewChild('tvContainer', {read: ViewContainerRef}) tvContainer!:ViewContainerRef;
  @ViewChild('canContainer', {read: ViewContainerRef}) canContainer!:ViewContainerRef;
  @ViewChild('pencilContainer', {read: ViewContainerRef}) pencilContainer!:ViewContainerRef;
  @ViewChild('turnTabletContainer', {read: ViewContainerRef}) turnTabletContainer!:ViewContainerRef;
  @ViewChild('machineContainer', {read: ViewContainerRef}) machineContainer!:ViewContainerRef;
  @ViewChild('chairContainer', {read: ViewContainerRef}) chairContainer!:ViewContainerRef;

  navigateToComponent(index: number, slide?: number) {
    this.navigate.emit(index);
    this.slideContact.emit(slide);
  }
  openLinkInNewWindow(url: string): void {
    window.open(url, '_blank');
  }
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private lazyLoadService: LazyLoadService,
    public soundService: SoundService
  ) {}

  ngAfterViewInit() {
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.eyesContainer, EyesComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.angelContainer, AngelComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.papersContainer, PapersComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.rollerContainer, RollerComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.robotContainer, RobotComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.busContainer, BusComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.phoneContainer, PhoneComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.womanContainer, WomanComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.canContainer, CanComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.turnTabletContainer, TurntableComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.machineContainer, MachineComponent);
    });
    requestIdleCallback(() => {
      this.lazyLoadService.loadComponent(this.chairContainer, ChairComponent);
    });
  }

  volume(boolean: boolean){
    this.soundService.setMute(boolean)
    this.mute = boolean;
  }
  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isMoving) {
      this.isMoving = true;
      requestAnimationFrame(() => {
        this.updateHolographicElement(event);
        this.isMoving = false;
      });
    }
  }

  updateHolographicElement(event: MouseEvent) {
    if (!this.holographicElement) {
      this.holographicElement = this.el.nativeElement.querySelector('.effect');
    }

    if (this.holographicElement) {
      const boundingRect = this.holographicElement.getBoundingClientRect();
      const centerX = boundingRect.left + boundingRect.width / 2;
      const centerY = boundingRect.top + boundingRect.height / 2;
      const offsetX = (centerX - event.clientX) / boundingRect.width;
      const offsetY = (centerY - event.clientY) / boundingRect.height;

      const transformOriginX = (offsetX + 0.5) * 100;
      const transformOriginY = (offsetY + 0.5) * 100;
      const translateX = offsetX * 10;
      const translateY = offsetY * 10;

      this.renderer.setStyle(this.holographicElement, 'transformOrigin', `${transformOriginX}% ${transformOriginY}%`);
      this.renderer.setStyle(this.holographicElement, 'transform', `translate(${translateX}px, ${translateY}px)`);
    }
  }
  response$: any;
  datos: any;
}
