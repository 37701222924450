import { Component, ViewChild, AfterViewInit, ElementRef, EventEmitter, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.css']
})
export class SplashscreenComponent implements AfterViewInit {
  private videoAlmostEnded = false;
  canSkip = false;
  @Output() navigate = new EventEmitter<number>();
  @ViewChild('videoPlayer1') videoplayer!: ElementRef<HTMLVideoElement | any>;
  isLoading: boolean = true;
  smShow: boolean = false;

  constructor(private router: Router) {}
  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
  ngOnInit(): void {
    if(innerWidth < 769){
      this.smShow = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event){
    if(window.innerWidth <769){
      this.smShow = true;
    }
  }
  ngAfterViewInit(): void {
    const videoPlayer = this.videoplayer.nativeElement;
    videoPlayer.ontimeupdate = () => this.checkIfAlmostEnded(videoPlayer);
    videoPlayer.onended = () => this.onVideoEnded();
  }

  loopLastSecond(videoPlayer: HTMLVideoElement | any): void {
    const videoDuration = videoPlayer.duration;

    videoPlayer.currentTime = videoDuration - 1;
    videoPlayer.play();

    videoPlayer.ontimeupdate = () => {
      if (videoPlayer.currentTime >= videoDuration) {
        videoPlayer.currentTime = videoDuration - 1;
        videoPlayer.play();
        // this.navigateToApp()
      }
    };
  }
  onVideoLoaded() {
    this.isLoading = false;
  }
  
  navigateToApp(): void {
    if (this.canSkip) {
      this.navigateToComponent(1)
    }
  }

  closebutton(): void {
    this.videoplayer.nativeElement.play();
  }

  checkIfAlmostEnded(video: HTMLVideoElement | any): void {
    const currentTime = video.currentTime;
    const duration = video.duration;

    if (duration - currentTime <= 3 && !this.videoAlmostEnded) {
      this.videoAlmostEnded = true;
      this.canSkip = true;
    }
  }

  onVideoEnded(): void {
    this.loopLastSecond(this.videoplayer.nativeElement);
  }
}
