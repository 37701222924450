<div class="ComponentCont" [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }">
<div class="BG1">
    <div class="FOOTER">
        <img width="1920" height= "1080" alt="FOOTER" loading="lazy" class="img-fluid" src="/assets/images/home/GROOVY_P2P/SLIDE1/FOOTER.webp">

    </div>
    <div class="row"> 
        <div class="col-md-7">
            <div class="communeTitle">
                <img width="1920" height= "1080" alt="title" loading="lazy" class="img-fluid" src="/assets/images/home/GROOVY_P2P/SLIDE1/COMMUNE.webp">
            </div>
            <div class="slideContent1">
                <p class="text">
                    Showcase your unique talents and <br>
                    knowledge within the cannabis <br>
                    community. Whether you're a seasoned <br>
                    grower, a marketing guru, or a budtender<br>
                    with expert recommendations, GROOVY <br>
                    Connect is your stage.
                </p>
                <p class="text mt-4">
                    Find the Services You Need: Easily <br>
                    search for and connect with trusted <br>
                    providers offering a wide range of<br>
                    cannabis-related services.
                </p>
            </div>
        </div>
        <div class="STICKER">
            <img width="1920" height= "1080" alt="sticker" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_P2P/SLIDE1/STICKER.webp"> 
        </div>
        <div class="col-md">

            <div class="badge">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>
            <div class="badge sm">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>
            <div class="badge">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>

            <div class="badge">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>

            <div class="badge sm">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>

            <div class="badge">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>
            <div class="badge sm">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>
            <div class="badge">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>
            <div class="badge sm">
                <img width="1920" height= "1080" alt="badge" loading="lazy" src="assets/images/home/GROOVY_P2P/SLIDE1/ELEMENT_P2P.webp" class="img-fluid">
            </div>
           
        </div>
    </div>
    <button class="backButton" (click)="handleSlideDesactivation(0)">
        BACK
      </button>
</div>
<div class="BG"  >
    <div class="row">
        <div class="col-md-6">  
            <div class="title">
                <img width="1920" height= "1080" alt="title" loading="lazy" src="assets/images/home/GROOVY_P2P/GROOVY_P2P.webp" alt="Movable Image" class="img-fluid">
              </div>
              <div class="slideContent">
                <p class="text text-center">
                    Coming soon to the GROOVY <br>
                    ecosystem, GROOVY Connect is a <br>
                    revolutionary platform designed to<br>
                    connect cannabis enthusiasts,<br>
                     professionals, and businesses in a <br>
                     thriving peer-to-peer marketplace.
                </p>
                <div class="slideFooter">
                    <div class="buttonsContainer">
                        <button class="btn-slider"  (click)="handleSlideActivation(1)">
                            <div class="btnText">
                                <span>C</span>
                                <span>o</span>
                                <span>m</span>
                                <span>m</span>
                                <span>u</span>
                                <span>n</span>
                                <span>e</span>
                              </div>
                            <div class="star top-right"></div>
                            <div class="star bottom-left"></div>
                        </button>
                          <button class="btn-slider"  (click)="handleSlideActivation(2)">
                            <div class="btnText">
                                <span>T</span>
                                <span>a</span>
                                <span>l</span>
                                <span>e</span>
                                <span>n</span>
                                <span>t</span>
                              </div>
                            <div class="star top-left"></div>
                            <div class="star bottom-right"></div>  
                        </button>
                    </div>
                    <p class="text text-center">
                        Share Your Skills, Earn Rewards,<br>
                        Collaborate, and Grow!
                    </p>
                </div>
                </div>


        </div>
        <div class="col-md">
    <div class="turnTable">
        <app-turntable></app-turntable>
    </div>
        </div>
    </div>  
</div>
<div class="BG2">
    <div class="row">
        <div class="col-md">
           
            <div class="slideContent2">
                <p class="text">
                    <b>COMMUNITY-DRIVEN: Built by and for the</b>  <br>
                    cannabis community, fostering <br>
                    collaboration, knowledge sharing, and<br>
                    economic empowerment.
                </p>
                <p class="text">
                    <b>SECURE & TRANSPARENT: Leverage</b><br>
                    GROOVY's blockchain technology for <br>
                    secure transactions and verifiable user<br>
                    reputations.
                </p>
                <p class="text">
                   <b> DIVERSE SERVICES: Find everything you</b> <br>
                    need to thrive in the cannabis industry,<br>
                    all in one convenient platform.
                </p>
            </div>
        </div>
        <div class="col-md">

        </div>
    </div>
    <button class="backButton2" (click)="handleSlideDesactivation(0)">
        BACK
      </button>
</div>
</div>