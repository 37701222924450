import { Component } from '@angular/core';

@Component({
  selector: 'app-groovy-p2p',
  templateUrl: './groovy-p2p.component.html',
  styleUrls: ['./groovy-p2p.component.css']
})
export class GroovyP2pComponent{
  slideIsActive: number = 0;
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }

}
