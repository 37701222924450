<div class="containerRobot" (mouseenter)="activateSound()" (mouseleave)="deactivateSound()">
    <div class="p-2">
        <div class="robotHead">
            <div class="robotMouth"></div>
            <img width="1920" height= "1080" alt="robot-head" class="img-fluid" src='assets/images/hero/robot/robotHead.svg' loading="lazy">
        </div>
        <div class="robotHand1">
            <img width="1920" height= "1080" alt="robot-hand1" c class="img-fluid" src='assets/images/hero/robot/robotHand1.svg' loading="lazy">
        </div>

        <div class="robotBody">
            <img width="1920" height= "1080" alt="robot-body" c class="img-fluid" src='assets/images/hero/robot/robotBody.svg' loading="lazy">
        </div>

        <div class="robotHand2">
            <img width="1920" height= "1080" alt="robot-hand2" c class="img-fluid" src='assets/images/hero/robot/robotHand2.svg' loading="lazy">
        </div>
    </div>
    <div class="splashContainer">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>