import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultModule } from './UI/layouts/default/default.module';
import { UserGateway } from './domain/models/User/gateway/user-gateway';
import { UserService } from './infraestructure/driven-adapter/services/user/user.service';
import { HttpClientModule } from '@angular/common/http';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { AuthComponent } from './UI/modules/auth/auth.component';
import { LoginComponent } from './UI/modules/login/login.component';
import { HeroModule } from './UI/modules/home/hero/hero.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    SocialLoginModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HeroModule,
    AppRoutingModule,
    HttpClientModule,
    DefaultModule,
  ],
  
  providers: [{provide : UserGateway, useClass: UserService},
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '990377587943-6bqluadiphc1rf1omvd214pedoodi6e9.apps.googleusercontent.com'
              )
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider('360666669950141')
            },
          //  {
          //      id: AppleLoginProvider.PROVIDER_ID,
          //     provider: new AppleLoginProvider('click.groovyweb','email', 'https://groovy.click')
          // }
          ],
          onError: (err: any) => {
            console.error(err);
          }
        } as SocialAuthServiceConfig,
      }
    ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
