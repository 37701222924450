
<div class="bg-groovy">
<app-back-to-top *ngIf="visibleIndex > 2" (reset)="scrollToComponent(1)"></app-back-to-top>

  <app-header
    (menuActivated)="handleMenuActivation()"
    (menuOff)="handleMenuDesactivation()"
    (navigate)="scrollToComponent($event)"
  ></app-header>
  <div
    class="BODYCONT"
    #bodyCont
    [ngClass]="{ hiddenLayout: menuIsActive === true }"
    class="scroll-container" (scroll)="onScroll($event)">
    <div class="scroll-content" #scrollContent id="scrollContent">
      <div class="moduleWrapper" #homeChild >
      <app-splashscreen  (navigate)="scrollToComponent($event)"></app-splashscreen>
      </div>
      <div class="moduleWrapper" #homeChild >

        <app-hero (navigate)="scrollToComponent($event)" (slideContact)="slideContact($event)" class="sm-none"></app-hero>
      </div>
        <div class="moduleWrapper" #homeChild >
      <app-affiliates-and-partners  (navigate)="scrollToComponent($event)" (slideContact)="slideContact($event)" class="sm-none"></app-affiliates-and-partners>
      </div>
      <div class="moduleWrapper" #homeChild >
        <app-about-us (navigate)="scrollToComponent($event)" (slideContact)="slideContact($event)" class="sm-none"></app-about-us>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-rewards-marketplace class="sm-none zIndex1"></app-rewards-marketplace>
      </div>
      <div class="moduleWrapper" #homeChild>
      <app-groovy-tags   class="sm-none"></app-groovy-tags>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-data-analytics  class="sm-none"></app-data-analytics>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-our-tech class="sm-none"></app-our-tech>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-social-media class="sm-none"></app-social-media>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-contact-us (navigate)="scrollToComponent($event)" (slideContact)="slideContact($event)" [currentContactSlide]="currentContactSlide" class="sm-none"></app-contact-us>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-groovy-p2p class="sm-none"></app-groovy-p2p>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-investors-relations class="sm-none"></app-investors-relations>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-web3 class="sm-none"></app-web3>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-groovy-rewards class="sm-none"></app-groovy-rewards>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-groovy-library (navigate)="scrollToComponent($event)" (slideContact)="slideContact($event)"  class="sm-none"></app-groovy-library>
      </div>
      <div class="moduleWrapper" #homeChild >
      <app-footer (navigate)="scrollToComponent($event)"  class="sm-none"></app-footer>
      </div>
    </div>
  </div>
</div>
