<div class="sphereCont">
    <div class="sphereBar">
        <img width="1920" height= "1080" alt="bar" class="img-fluid" src="/assets/images/hero/sphere/BAR.webp" loading="lazy">
    </div>
    <div class="sphere">
        <img width="1920" height= "1080" alt="sphere" class="img-fluid" src="/assets/images/hero/sphere/SPHERE.webp" loading="lazy">
        <div class="btn-Positioner">
            <button class="btn-slider2">
                <div class="btnText2">
                  <span class="one">E</span>
                  <span class="two">Y</span>
                  <span class="three">E</span>
                  <span class="four">S</span>
    
                </div>
                <div class="star first"></div>
                <div class="star second"></div> 
                <div class="star third"></div> 

              </button>
        </div>
       
    </div>
    
    </div>