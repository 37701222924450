import { Component } from '@angular/core';

@Component({
  selector: 'app-turntable',
  templateUrl: './turntable.component.html',
  styleUrls: ['./turntable.component.css']
})
export class TurntableComponent {

}
