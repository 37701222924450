
<div class="container zf-templateWidth">
    <form action="https://forms.zohopublic.com/groovycompanyinc/form/CONTACTUS1/formperma/G5cMBHuSiXcDEfqwO4aOKIFTDmzRODVVlH59HQNhvkw/htmlRecords/submit" 
    name="form" 
    method="POST" 
    onSubmit="return openAndSubmitForm(event);"
    accept-charset="UTF-8" 
    enctype="multipart/form-data" 
    id="form"
    target="_blank">
      <input type="hidden" name="zf_referrer_name" value="">
      <input type="hidden" name="zf_redirect_url" value="">
      <input type="hidden" name="zc_gad" value="">
      
      <div class="zf-templateWrapper">
  
        <div class="zf-subContWrap zf-topAlign">
          <ul class="ComponentCont" [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }">


            <li class="col-md">
              <ul class="list-unstyled">
                  <li class="zf-tempFrmWrapper zf-medium">
                      <label class="zf-labelName">
                          Company Name <em class="zf-important">*</em>
                      </label>
                      <div class="zf-tempContDiv">
                          <span>
                              <input
                                  style="width: 90%!important; position: relative; top: -10px;"
                                  type="text"
                                  name="SingleLine"
                                  checktype="c1"
                                  value=""
                                  maxlength="255"
                                  fieldType="1"
                                  placeholder="Type Company Name"
                              />
                          </span>
                          <p id="SingleLine_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
                      </div>
                      <div class="zf-clearBoth"></div>
                  </li>
          
                  <li class="zf-tempFrmWrapper zf-name">
                      <label class="zf-labelName">
                          Name <em class="zf-important">*</em>
                      </label>
                      <div class="zf-tempContDiv zf-twoType">
                          <div class="zf-nameWrapper d-flex flex-column">
                              <span>
                                  <input
                                      style="width: 90%!important; position: relative; top: -10px;"
                                      type="text"
                                      maxlength="255"
                                      name="Name_First"
                                      fieldType="7"
                                      placeholder="Type First Name"
                                  />
                              </span>
                              <span>
                                  <input
                                      style="width: 90%!important; position: relative; top: -5px;"
                                      type="text"
                                      maxlength="255"
                                      name="Name_Last"
                                      fieldType="7"
                                      placeholder="Type Last Name"
                                  />
                              </span>
                              <div class="zf-clearBoth"></div>
                          </div>
                          <p id="Name_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
                      </div>
                      <div class="zf-clearBoth"></div>
                  </li>
          
                  <li class="zf-tempFrmWrapper zf-small">
                      <label class="zf-labelName">
                          Email <em class="zf-important">*</em>
                      </label>
                      <div class="zf-tempContDiv">
                          <span>
                              <input
                                  style="width: 90%!important; position: relative; top: -10px;"
                                  type="text"
                                  maxlength="255"
                                  name="Email"
                                  checktype="c5"
                                  value=""
                                  placeholder="Type Email"
                              />
                          </span>
                          <p id="Email_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
                      </div>
                      <div class="zf-clearBoth"></div>
                  </li>
          
                  <li class="zf-tempFrmWrapper zf-small">
                      <label class="zf-labelName">
                          Phone <em class="zf-important">*</em>
                      </label>
                      <div class="zf-tempContDiv zf-phonefld">
                          <div class="zf-phNumber">
                              <span>
                                  <input
                                      style="width: 90%!important; position: relative; top: -10px;"
                                      type="text"
                                      compname="PhoneNumber"
                                      name="PhoneNumber_countrycode"
                                      maxlength="20"
                                      checktype="c7"
                                      value=""
                                      phoneFormat="1"
                                      isCountryCodeEnabled="false"
                                      fieldType="11"
                                      id="international_PhoneNumber_countrycode"
                                      valType="number"
                                      phoneFormatType="2"
                                      placeholder="Type Phone Number"
                                  />
                              </span>
                              <div class="zf-clearBoth"></div>
                          </div>
                          <p id="PhoneNumber_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
                      </div>
                      <div class="zf-clearBoth"></div>
                  </li>
          
                  <li>
                      <span class="btn-slider" (click)="handleSlideActivation(1)" style="top: -30px;">
                          <div class="btnText">
                              <span>N</span>
                              <span>e</span>
                              <span>x</span>
                              <span>t</span>
                          </div>
                      </span>
                  </li>
              </ul>
          </li>
          
    
          <li class="col-md">
            <div class="row">
                <div class="col-md">
                    <ul class="list-unstyled">
                        <li class="zf-tempFrmWrapper zf-address zf-addrlarge">
                            <label class="zf-labelName">
                                Address
                            </label>
                            <div class="zf-tempContDiv zf-address">
                                <div class="zf-addrCont">
                                    <span class="zf-addOne">
                                        <input
                                            type="text"
                                            maxlength="255"
                                            name="Address_AddressLine1"
                                            checktype="c1"
                                            placeholder="Type Street Address"
                                        />
                                    </span>
                                    <span class="zf-addOne">
                                        <input
                                            type="text"
                                            maxlength="255"
                                            name="Address_AddressLine2"
                                            checktype="c1"
                                            placeholder="Type Address Line2"
                                        />
                                    </span>
                                    <span class="zf-flLeft zf-addtwo">
                                        <input
                                            type="text"
                                            maxlength="255"
                                            name="Address_City"
                                            checktype="c1"
                                            placeholder="Type City"
                                        />
                                    </span>
                                    <span class="zf-flLeft zf-addtwo">
                                        <input
                                            type="text"
                                            maxlength="255"
                                            name="Address_Region"
                                            checktype="c1"
                                            placeholder="Type State/Region/Province"
                                        />
                                    </span>
                                    <span class="zf-flLeft zf-addtwo">
                                        <input
                                            type="text"
                                            maxlength="20"
                                            name="Address_ZipCode"
                                            checktype="c1"
                                            placeholder="Type Postal/Zip Code"
                                        />
                                    </span>
                                    <span class="zf-flLeft zf-addtwo">
                                        <label for="selection"></label>
                                        <select
                                            id="selection"
                                            (focus)="loadCountries()"
                                            class="zf-form-sBox"
                                            name="Address_Country"
                                            checktype="c1"
                                        >
                                            <option value="-Select-">Select Country</option>
                                            <option *ngFor="let country of countries">{{ country }}</option>
                                        </select>
                                    </span>
                                    <div class="zf-clearBoth"></div>
                                    <p id="Address_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
                                    <div class="zf-clearBoth"></div>
                                </div>
                            </div>
                            <div class="zf-clearBoth"></div>
                        </li>
                    </ul>
                </div>
        
                <div class="col-md">
                    <ul class="list-unstyled">
                        <li style="padding-left: 60px;" class="zf-radio zf-tempFrmWrapper zf-oneColumns">
                            <label class="zf-labelName">Please choose
                                <em class="zf-important">*</em>
                            </label>
                            <div class="zf-tempContDiv">
                                <div class="zf-overflow">
                                    <span class="zf-multiAttType">
                                        <input
                                            class="zf-radioBtnType"
                                            type="radio"
                                            id="Radio_1"
                                            name="Radio"
                                            checktype="c1"
                                            value="Partner"
                                        />
                                        <label for="Radio_1" class="zf-radioChoice">Partner</label>
                                    </span>
                                    <span class="zf-multiAttType">
                                        <input
                                            class="zf-radioBtnType"
                                            type="radio"
                                            id="Radio_2"
                                            name="Radio"
                                            checktype="c1"
                                            value="Affiliate"
                                        />
                                        <label for="Radio_2" class="zf-radioChoice">Affiliate</label>
                                    </span>
                                    <span class="zf-multiAttType">
                                        <input
                                            class="zf-radioBtnType"
                                            type="radio"
                                            id="Radio_3"
                                            name="Radio"
                                            checktype="c1"
                                            value="Manufacture"
                                        />
                                        <label for="Radio_3" class="zf-radioChoice">Manufacture</label>
                                    </span>
                                    <span class="zf-multiAttType">
                                        <input
                                            class="zf-radioBtnType"
                                            type="radio"
                                            id="Radio_4"
                                            name="Radio"
                                            checktype="c1"
                                            value="Consumer"
                                        />
                                        <label for="Radio_4" class="zf-radioChoice">Consumer</label>
                                    </span>
                                    <span class="zf-multiAttType">
                                        <input
                                            class="zf-radioBtnType"
                                            type="radio"
                                            id="Radio_5"
                                            name="Radio"
                                            checktype="c1"
                                            value="Shareholder"
                                        />
                                        <label for="Radio_5" class="zf-radioChoice">Shareholder</label>
                                    </span>
                                    <span class="zf-multiAttType">
                                        <input
                                            class="zf-radioBtnType"
                                            type="radio"
                                            id="Radio_6"
                                            name="Radio"
                                            checktype="c1"
                                            value="Other"
                                        />
                                        <label for="Radio_6" class="zf-radioChoice">Other</label>
                                    </span>
                                    <div class="zf-clearBoth"></div>
                                </div>
                                <p id="Radio_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
                            </div>
                            <div class="zf-clearBoth"></div>
                        </li>
                    </ul>
                </div>

                <ul>
                  <li class="zf-tempFrmWrapper zf-medium">
                    <label for="textarea" class="zf-labelName">
                        Additional information
                    </label>
                    <div class="zf-tempContDiv">
                        <span>
                            <textarea
                                id="textarea"
                                class="textAREA"
                                name="MultiLine"
                                checktype="c1"
                                maxlength="65535"
                                placeholder=""
                            ></textarea>
                        </span>
                        <p id="MultiLine_error" class="zf-errorMessage" style="display:none;">Invalid value</p>
                    </div>
                    <div class="zf-clearBoth"></div>
                </li>

                <li style="display: flex; gap: 15px;" class="zf-tempFrmWrapper">
                    <label class="zf-labelName" for="fileUpload">File Upload</label>
                    <div class="zf-tempContDiv">
                        <input
                            type="file"
                            id="fileUpload"
                            name="FileUpload"
                            checktype="c1"
                        />
                        <p id="FileUpload_error" class="zf-errorMessage" style="display:none;">Choose any file for this field.</p>
                    </div>
                    <div class="zf-clearBoth"></div>
                </li>

                <li class="buttonsContainer">
                    <span class="btn-slider" (click)="handleSlideDesactivation(0)">
                        <div class="btnText">
                            <span>B</span>
                            <span>a</span>
                            <span>c</span>
                            <span>k</span>
                        </div>
                    </span>
                    <button
                        class="btn-slider"
                        type="submit"
                        id="formsubmit"
                        onclick="(event) => {
                            event.preventDefault();
                            var windowFeatures = 'width=600,height=400,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes';
                            var newWindow = window.open('', '_blank', windowFeatures);
                            document.getElementById('form').target = '_blank';
                            document.getElementById('form').submit();
                        }"
                    >
                        <div class="btnText">
                            <span>S</span>
                            <span>e</span>
                            <span>n</span>
                            <span>d</span>
                        </div>
                    </button>
                </li>
              </ul>
            </div>
        </li>
        

         
  </ul>
    
        </div>
  
        <ul>
          <li class="zf-fmFooter">
            <div class="zf-submitOuter">
                <div class="zf-submitWrapper">
                  
                  
                </div>
              <div class="zf-clearBoth"></div>
            </div>
          </li>
        </ul>
      </div>
    </form>
  </div>