<div class="row justify-content-center my-5">
    <div class="col-4">
      <div class="row" *ngIf="user.photoUrl">
        <div class="col mb-2 text-center">
      
          <img width="1920" height= "1080" loading="lazy" [src]="user.photoUrl" class="rounded-circle w-25">
        </div>
      </div>
      <div class="row">
        <div class="col mb-2">
          <label for="email" class="form-label">Email:</label>
          <input type="email" id="email" name="email" #email="ngModel" [ngModel]="user.email" class="form-control form-control-sm">
        </div>
      </div>
      <div class="row">
        <div class="col mb-2">
          <label for="nickname" class="form-label">Nickname:</label>
          <input type="text" id="nickname" name="nickname" #nickname="ngModel" [(ngModel)]="user.firstName" class="form-control form-control-sm">
        </div>
      </div>
    </div>
  </div>