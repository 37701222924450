import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-groovy-rewards',
  templateUrl: './groovy-rewards.component.html',
  styleUrls: ['./groovy-rewards.component.css']
})
export class GroovyRewardsComponent {
  slideIsActive: number = 0;

  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }

}
