import { Component, HostListener, ViewChildren, QueryList, ElementRef, AfterViewInit, ViewChild, Renderer2 } from '@angular/core';
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements AfterViewInit {
  menuIsActive: boolean = false;
  index: number = 0;
  currentContactSlide: number = 1;
  private scrollY = 0;
  private scrollTimeout: any = null;
  private scrollDelay: number = 100; 
  private lastExecution: number = 0;
  private throttleDelay: number = 2000;

  @ViewChildren('homeChild' , {read: ElementRef})homeChildren!: QueryList<any>;
  @ViewChild('scrollContent', { static: true }) scrollContent!: ElementRef;
  
  slideContact(slide: number){
    this.currentContactSlide = slide;
  }

  constructor(
    private renderer: Renderer2
  ) {
  }
  private throttle(fn: Function, delay: number) {
    return (...args: any[]) => {
      const now = Date.now();
      if (now - this.lastExecution >= delay) {
        this.lastExecution = now;
        fn(...args);
      }
    };
  }
  private throttledOnScroll = this.throttle(this.onScrollHandler.bind(this), this.throttleDelay);
  private onScrollHandler(event: any) {
    event.preventDefault();

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.handleScroll(event);
    }, this.scrollDelay);
  }

  private handleScroll(event: any) {
    this.updateVisibleIndex();
    let adjustedDeltaY;
    if (event.deltaY > 0) {
      adjustedDeltaY = 1;
    } else {
      adjustedDeltaY = 0;
    }
    const canScroll = !(this.visibleIndex === 1 || this.visibleIndex === 2 && adjustedDeltaY === 0);

    if (canScroll) {
      if (this.scrollContent) {
        this.applyScroll(adjustedDeltaY);
      }
    }
  }
  
  homeChildPositions: number[] = [];
  visibleIndex: number = 0;


  ngAfterViewInit() {
    this.calculateHomeChildPositions();
    window.addEventListener('resize', this.calculateHomeChildPositions.bind(this));
  }
  ngOnDestroy() {
    window.removeEventListener('resize', this.calculateHomeChildPositions.bind(this));
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateHomeChildPositions();
  }


  @HostListener('wheel', ['$event'])
   onScroll(event: any) {
    this.throttledOnScroll(event);
  }

  ngOnInit(): void {
    this.renderer.listen('window', 'scroll', this.throttledOnScroll);
  }
  applyScroll = (deltaY: number) => {
    if (deltaY === 1) {
      this.scrollToComponent(this.visibleIndex);
    } else if (deltaY === 0) {
        this.scrollToComponent((this.visibleIndex - 2));
        }
    this.updateVisibleIndex();
    console.log(this.visibleIndex)

  };
  
  handleMenuActivation() {
    this.menuIsActive = true;
  }

  handleMenuDesactivation() {
    this.menuIsActive = false;
  }

  reset() {
    this.scrollY = 0;
    if (this.scrollContent) {
      this.scrollContent.nativeElement.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)`;
    }
    this.updateVisibleIndex();

  }

  calculateHomeChildPositions() {
    if (this.homeChildren && this.homeChildren.length) {
      this.homeChildPositions = this.homeChildren.map(child => child.nativeElement.offsetTop);
    }
  }
  updateVisibleIndex() {
    const segmentHeight = window.innerHeight;
    const scrollPosition = this.scrollY + segmentHeight / 2; 
  
    let index = this.homeChildPositions.findIndex(position => scrollPosition < position);
  
    if (index === -1) {
      index = this.homeChildPositions.length;
    }
  
    this.visibleIndex = index;
    
  
    if (this.visibleIndex < 0) {
      this.visibleIndex = 0;
    } else if (this.visibleIndex >= this.homeChildPositions.length) {
      this.visibleIndex = this.homeChildPositions.length;
    }
  }
  
  
  
  isVisible(componentId: string): boolean {
  const componentIndex = this.homeChildren.toArray().findIndex(child => child.nativeElement.id === componentId);

  return componentIndex === this.visibleIndex;
  }
  scrollToComponent(index: number) {
    this.updateVisibleIndex();
    this.calculateHomeChildPositions();
  
    if (index >= 0 && index < this.homeChildPositions.length) {
      const newScrollY = this.homeChildPositions[index];
  
      if (newScrollY !== this.scrollY) {
        this.scrollY = newScrollY;
  
        if (this.scrollContent) {
          const yOffset = -this.scrollY;
  
          requestAnimationFrame(() => {
            const scrollElement = this.scrollContent.nativeElement;
            
            scrollElement.classList.add('smooth-scroll');
            scrollElement.style.transform = `translateY(${yOffset}px)`;
  
            requestAnimationFrame(() => {
              scrollElement.classList.remove('smooth-scroll');
            });
          });
        }
      }
    }
  
    this.updateVisibleIndex();
  }
  
  
}
