import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {



  user: any;

  constructor() {
    this.user = {};
  }

  public ngOnInit(): void {
    
     window.groovy.isConnected().then((response: any)=>{
      this.user = response
     })
  }


}
