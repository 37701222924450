<div class="busContainer" (mouseenter)="activateSound()" (mouseleave)="deactivateSound()">
    <div class="busBody">
        <div class="busElements">
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="busWheel">
        <img width="1920" height= "1080" alt="busWheel"  class="img-fluid" src="assets/images/hero/bus/wheel.svg" loading="lazy">
    </div>
    <span class="busShadow"></span>

</div>