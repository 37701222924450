<div class="d-flex postion-fixed" id="menuPositioner">
    <button class="menuButton" (click)="menuActive === true ? onMenuClick(false, 0) : onMenuClick(true, 0)" aria-label="_">
        <div class="menu" *ngIf="menuActive === false">
            <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/menu/HamburguerMenu.webp" alt="Menu1">
        </div>
        <div class="menu" *ngIf="menuActive === true">
            <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/menu/HamburguerMenuX.webp" alt="Menu2">
        </div>
    </button>
    <div>
        <div class="bgMENU" [ngClass]="{'Hidden': menuActive === false, 'Hover': activeLink !== ''}">
            <div class="container">
                <div class="row">
                    <div class="col-md-7 d-flex align-center">
                        <div class="d-flex flex-column" id="navITEMS">
                            <button  aria-label="_" class="nav-linkC" id="Partners" href="/" (click)="onMenuClick(false, 2)"
                                (mouseover)="activeLink = 'AFFILIATES_&_PARTNERS2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('AFFILIATES_&_PARTNERS2')" [ngClass]="getClass('AFFILIATES_&_PARTNERS2')" alt="AFFILIATES & PARTNERS">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="AboutUS" href="/" (click)="onMenuClick(false, 3)"
                                (mouseover)="activeLink = 'ABOUT_US2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('ABOUT_US2')" [ngClass]="getClass('ABOUT_US')" alt="ABOUT US">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="RewardsMarketplace" href="/" (click)="onMenuClick(false,4)"
                                (mouseover)="activeLink = 'REWARDS_MARKET_PLACE'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('REWARDS_MARKET_PLACE')" [ngClass]="getClass('REWARDS_MARKETPLACE')" alt="REWARDS MARKETPLACE">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="GroovyTags" href="/" (click)="onMenuClick(false,5)"
                                (mouseover)="activeLink = 'GROOVY_TAGS2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('GROOVY_TAGS2')" [ngClass]="getClass('GROOVY_TAGS')" alt="GROOVY TAGS">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="DataAnalytics" href="/" (click)="onMenuClick(false,6)"
                                (mouseover)="activeLink = 'DATA_ANALYTICS2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('DATA_ANALYTICS2')" [ngClass]="getClass('DATA_ANALYTICS')" alt="DATA ANALYTICS">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="OurTech" href="/" (click)="onMenuClick(false,7)"
                                (mouseover)="activeLink = 'OUR_TECH2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('OUR_TECH2')" [ngClass]="getClass('OUR_TECH')" alt="OUR TECH">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="SocialMedia" href="/" (click)="onMenuClick(false,8)"
                                (mouseover)="activeLink = 'GROOVY_COMMUNE'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('GROOVY_COMMUNE')" [ngClass]="getClass('SOCIAL_MEDIA')" alt="SOCIAL MEDIA">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="ContactUS" href="/" (click)="onMenuClick(false, 9)"
                                (mouseover)="activeLink = 'CONTACT_US2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('CONTACT_US2')" [ngClass]="getClass('CONTACT_US')" alt="CONTACT US">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="GroovyP2P" href="/" (click)="onMenuClick(false, 10)"
                                (mouseover)="activeLink = 'GROOVY_P2P2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('GROOVY_P2P2')" [ngClass]="getClass('GROOVY_P2P')" alt="GROOVY P2P">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="InvestorsRelations" href="/" (click)="onMenuClick(false, 11)"
                                (mouseover)="activeLink = 'INVESTORS_RELATIONS2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('INVESTORS_RELATIONS2')" [ngClass]="getClass('INVESTORS_RELATIONS')" alt="INVESTORS RELATIONS">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="Web3" href="/" (click)="onMenuClick(false, 12)"
                            (mouseover)="activeLink = 'WEB3'" (mouseout)="activeLink = ''">
                            <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('WEB3')" [ngClass]="getClass('WEB3')" alt="WEB3">
                        </button>
                            <button  aria-label="_" class="nav-linkC" id="GroovyRewards" href="/" (click)="onMenuClick(false, 13)"
                                (mouseover)="activeLink = 'GROOVY_REWARDS2'" (mouseout)="activeLink = ''">
                                <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('GROOVY_REWARDS2')" [ngClass]="getClass('GROOVY_REWARDS')" alt="GROOVY REWARDS">
                            </button>
                            <button  aria-label="_" class="nav-linkC" id="GENO_CLONE_LIBRARY" href="/" (click)="onMenuClick(false, 14)"
                            (mouseover)="activeLink = 'GENO_CLONE_LIBRARY'" (mouseout)="activeLink = ''">
                            <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('GENO_CLONE_LIBRARY')" [ngClass]="getClass('GENO_CLONE_LIBRARY')" alt="GENO_CLONE_LIBRARY">
                        </button>
                        <button  aria-label="_" class="nav-linkC" id="GroovyFooter" href="/" (click)="onMenuClick(false, 15)"
                        (mouseover)="activeLink = 'GROOVY_FOOTER'" (mouseout)="activeLink = ''">
                        <img width="inherit" height= "auto" loading="lazy" [src]="getImageSrc('GROOVY_FOOTER')" [ngClass]="getClass('GROOVY_FOOTER')" alt="GROOVY FOOTER">
                    </button>
                        </div>
                        
                        
                        
                    </div>
                    <div class="col-md">
                        <div class="menuElement" *ngIf="activeLink !== ''"
                            [ngClass]="{
                            'ELEMENT_1': activeLink === 'AFFILIATES_&_PARTNERS2', 
                            'ELEMENT_2': activeLink === 'ABOUT_US2', 
                            'ELEMENT_3': activeLink === 'REWARDS_MARKET_PLACE', 
                            'ELEMENT_4': activeLink === 'GROOVY_TAGS2', 
                            'ELEMENT_5': activeLink === 'DATA_ANALYTICS2', 
                            'ELEMENT_6': activeLink === 'OUR_TECH2', 
                            'ELEMENT_7': activeLink === 'GROOVY_COMMUNE', 
                            'ELEMENT_8': activeLink === 'CONTACT_US2', 
                            'ELEMENT_9': activeLink === 'GROOVY_P2P2', 
                            'ELEMENT_10': activeLink === 'INVESTORS_RELATIONS2', 
                            'ELEMENT_11': activeLink === 'WEB3', 
                            'ELEMENT_12': activeLink === 'GENO_CLONE_LIBRARY', 
                            'ELEMENT_13': activeLink === 'GROOVY_REWARDS2',
                            'ELEMENT_14': activeLink === 'GROOVY_FOOTER'
                        }
                            ">
                        </div>
                    </div>
                </div>
            </div>
        </div>