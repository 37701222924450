import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-rewards-marketplace',
  templateUrl: './rewards-marketplace.component.html',
  styleUrls: ['./rewards-marketplace.component.css']
})
export class RewardsMarketplaceComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  public buttonOpacity: number = 1;
  slideIsActive: number = 0;
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }

  constructor() { }

  ngOnInit(): void {
  }

  
}
