import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SoundService } from 'src/app/infraestructure/driven-adapter/services/sound.service';

@Component({
  selector: 'app-papers',
  templateUrl: './papers.component.html',
  styleUrls: ['./papers.component.css']
})
export class PapersComponent implements OnInit {
  soundActive: boolean = false;
  hoverSound: HTMLAudioElement | any;
  mute: boolean = false;
  private subscription!: Subscription;

  constructor(private soundService: SoundService) {}

  ngOnInit() {
    this.subscription = this.soundService.mute$.subscribe(mute => {
      this.mute = mute;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private loadAudio() {
    if (!this.hoverSound) {
      this.hoverSound = new Audio('assets/sounds/papers.mp3');
      this.hoverSound.loop = true;
    }
  }

  activateSound(): any {
    if (this.mute === true) {
      return null;
    } else {
      this.loadAudio();
      this.soundActive = true;
      this.hoverSound.play();
    }
  }

  deactivateSound() {
    if (this.hoverSound) {
      setTimeout(() => {
        this.hoverSound.currentTime = 0;
        this.hoverSound.pause();
        this.soundActive = false;
      }, 600);
    }
  }
}
