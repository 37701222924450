<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" >  
  <div class="BG1">
   
<div class="row">
    <div class="col-md">
    <div class="partnersCard">
      <h6 class="text-white">Leading Testing <br> Laboratories:</h6>
      <p>
        Partnering with accredited cannabis testing labs ensures the highest standards of data integrity, verifying cannabinoid profiles, terpene analysis, and other crucial metrics for GROOVY-certified products.
      </p>
    </div>
    </div>
    <div class="col-md">
      <div class="partners-title"  (click)="navigateToComponent(9,0)">
        <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/PARTNERS/PARTNERS_TITLE.webp" alt="title">
      </div>
    <div class="partnersCard">

      <h6 class="text-white">Technology 
        <br> Innovators:</h6>

        <p class="text-center">
          We collaborate with cutting-edge technology providers specializing in blockchain security, AI analytics, and smart tagging to continually enhance the GROOVY platform's capabilities and user experience.

          </p>
          </div>
    </div>
    <div class="col-md">
      <div class="swoosh">
        <img width="1920" height= "1080" alt="swoosh" loading="lazy" class="img-fluid" src="assets/images/home/AFFILIATES_&_PARTNERS/PARTNERS/SWOOSH.webp">
      </div>
      <div class="partnersCard">
        <h6 class="text-white">Regulatory & <br>Compliance<br> Experts
        </h6>
        <p>
          Working closely with legal and regulatory consultants keeps us ahead of the curve, ensuring that GROOVY remains compliant with evolving cannabis laws and regulations at both state and federal levels.
        </p>
      </div>
    </div>
    <button class="backButton" (click)="handleSlideDesactivation(0)">
      BACK
    </button>
    <button class="readButton" alt="Read More" (click)="openLink('document/affiliates_&_partners')">
      Read More
      </button>
    </div>
    <div class="clouds">

    </div>
    </div>
    <div class="BG">
      <div class="row">
        <div class="">  
            <div class="title">
                <img width="1920" height= "1080" loading="lazy" src="assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES_&_PARTNERSR.webp" alt="Movable Image" class="img-fluid">
              </div>
              <div class="">
                <div class="slider-content">
                  <div class="fueling">
                    <img width="1920" height= "1080" alt="cannabis" loading="lazy" class="img-fluid" src="assets/images/home/AFFILIATES_&_PARTNERS/FUELING_THE_FUTURE_OF_CANNABIS.webp">
                  </div>

                  <p class="text text-center mt-4">
                    Our Mission is to cultivate a thriving network of trusted <br>
                    partners and affiliates who share our commitment <br>
                    to authenticity, transparency, and data-driven <br>
                    innovation in the cannabis industry.
                  </p>

                  <div class="buttonsContainer">
                    <button class="btn-slider" id="partners" (click)="handleSlideActivation(1)">
                      <div class="btnText">
                        <span>P</span>
                        <span>a</span>
                        <span>r</span>
                        <span>t</span>
                        <span>n</span>
                        <span>e</span>
                        <span>r</span>
                        <span>s</span>
                      </div>
                      <div class="star top-right"></div>
                      <div class="star bottom-left"></div>
                    </button>
                    <button class="btn-slider" id="affiliates" (click)="handleSlideActivation(2)">
                      <div class="btnText">
                        <span>A</span>
                        <span>f</span>
                        <span>f</span>
                        <span>i</span>
                        <span>l</span>
                        <span>i</span>
                        <span>a</span>
                        <span>t</span>
                        <span>e</span>
                        <span>s</span>
                      </div>
                    </button>
                  </div>

                  <div class="mt-2 firstSlideFooter">
                    <div class="h-animationText">
                    <h4 class="Become">Become a part of the future of cannabis.</h4>
                    <h4 class="Become">Become a part of the future of cannabis.</h4>
                    <h4 class="Become">Become a part of the future of cannabis.</h4>
                    <h4 class="Become">Become a part of the future of cannabis.</h4>
                    <h4 class="Become">Become a part of the future of cannabis.</h4>

                    </div>

                    <p class="text">
                      Partner with us to drive innovation, build trust, and unlock the <br>
                      full potential of data-driven insights for the entire industry.

                    </p>
  
                  </div>
                </div>
             
     
                
            </div>
        </div>
        <div class="">
            <div class="angel">
                <img width="1920" height= "1080" alt="angel" loading="lazy" class="img-fluid" src="assets/images/home/AFFILIATES_&_PARTNERS/ANGEL.webp"> 
            </div>
        </div>
    </div>  
</div>
<div class="BG2">
  <div class="affiliates-title-position">

  <div class="affiliates-title"  (click)="navigateToComponent(9,0)">
    <img width="1920" height= "1080" alt="title" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TITLE.webp">
  </div>
</div>

  <div class="row" style="margin-left: inherit!important; height: 100%;">
    <div class="col-md-6">
      <div class="affiliatesCardCont">
        <div class="affiliatesMovingTextCont">
          <div class="affiliatesMovingText">
    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
          </div>
          <div class="affiliatesMovingText">
            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                  </div>
                  <div class="affiliatesMovingText">
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                          </div>
                          <div class="affiliatesMovingText">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                                  </div>
                                  <div class="affiliatesMovingText">
                                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                                          </div>
                                          <div class="affiliatesMovingText">
                                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                                                  </div>
                                                  <div class="affiliatesMovingText">
                                                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                                                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="/assets/images/home/AFFILIATES_&_PARTNERS/AFFILIATES/AFFILIATES_TEXT.webp">
                                                          </div>
          
        </div>
      </div>
    </div>
    <div class="col-md">
 
      <div class="textContAffiliates">

   <div class="textSlide">
    <h4>Growers:</h4>
       <p> Showcase your unique genetics,
protect your intellectual property, and earn
royalties when your strains are verified with
Groovy Tags. 
       </p>
      </div>
      <div class="textSlide">
    <h4>Retailers:</h4>

        <p>
          Attract discerning customers seeking
verified products, participate in exclusive
promotions, and streamline inventory
management with GROOVY tools.

         
        </p> 
      </div>
      <div class="textSlide">
        <h4>Manufacturers:</h4>
        <p>
        Prove product authenticity,
        enhance supply chain management, and gain
        valuable market insights to refine your
        offerings.
      </p>
      </div>
    </div>

    </div>
    <button class="backButton2" (click)="handleSlideDesactivation(0)">
      Back
    </button>
    <button class="readButton2" alt="Read More" (click)="openLink('document/affiliates_&_partners')">
    Read More
    </button>
    </div>

</div>
</div>