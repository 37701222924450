
<div class="heroCont">
<div class="BG-COLOR img-fluid"  >

<div class="effect" > 
<div class="BGHero">
  <div class="BGIMG">
    <img src="assets/images/hero/BG.webp" alt="Background Hero Image"    width="1920" 
    height="1080"
    style="width: 100%; height: auto;"
    srcset="
      assets/images/hero/BG2.webp 1280w,
      assets/images/hero/BG.webp 1920w"
    sizes="
            (max-width: 1280px) 1900px,
           1920px">
  </div>
  <div class="area13" (click)="openLinkInNewWindow('https://eyes.groovy.click/')">
    <div #eyesContainer></div>
  </div>
    <div class="window area1" >
        <div class="angel" (click)="navigateToComponent(2)">
            <div  #angelContainer></div>
        </div>
    </div>
    <div class="area2" (click)="navigateToComponent(3)" >
      <div #papersContainer></div>
    </div>
    <div class="area4" >
    <div class="logo">
        <img width="1920" height= "1080" alt="logo" loading="lazy" src="/assets/images/hero/Logo.svg" class="img-fluid w-100">
        </div>
        <div class="rollers" (click)="navigateToComponent(8)">
          <div #rollerContainer></div>
        </div>
    </div>
    <div class="area3" (click)="navigateToComponent(7)">
      <div #robotContainer></div>
    </div>
    <div class="area10" (click)="navigateToComponent(6)">
      <div #busContainer></div>
    </div>
  
    <div class="area5" (click)="navigateToComponent(9)" >
      <div #phoneContainer></div>
    </div>
    <div class="area6" (click)="navigateToComponent(13)" >
      <div #womanContainer></div>
    </div>
    <div class="rainbow" >  
      <img width="1920" height= "1080" alt="rainbow" loading="lazy" class="img-fluid" src="assets/images/hero/Rainbow.webp">
    </div>
    <div class="area8">
      <div class="googlePlay">
        <img width="1920" height= "1080" alt="googleplay" loading="lazy" class="img-fluid" src="assets/images/hero/Googleplay.webp">
      </div>
      <div class="appStore">
        <img width="1920" height= "1080" alt="appstore" loading="lazy" class="img-fluid" src="assets/images/hero/AppStore.webp">
      </div>

    </div>
   
    <div class="elementsContainer area12">
       
      <div class="tv" (click)="navigateToComponent(4)">
        <app-tv></app-tv>
      </div>
        <div class="tableTv" >
            <div>
              <div #canContainer (click)="navigateToComponent(5)"></div>
                <app-pencil class="Pencil" (click)="navigateToComponent(9,0)"></app-pencil>
            </div>
        </div>
      </div>
      <div class="audio-control">
  <button (click)="volume(true)" *ngIf="mute === false">
  <img width="1920" height= "1080" alt="ON" loading="lazy" aria-label="volumne" class="img-fluid" src="assets/images/sound/ON.webp">
  </button>
  <button (click)="volume(false)" *ngIf="mute === true">
    <img width="1920" height= "1080" alt="OFF" loading="lazy"  aria-label="volume" class="img-fluid" src="assets/images/sound/OFF.webp">
  </button>
</div>
      <div class="area11" >
        <div class="turnTablet" (click)="navigateToComponent(10)">
            <div #turnTabletContainer></div>
        </div>
        <div class="machine" (click)="navigateToComponent(11)">
          <div #machineContainer></div>
        </div>
        <div class="chair" (click)="navigateToComponent(14)">
          <div #chairContainer></div>

        </div>
        </div>
</div>
</div>
</div>
</div>