import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent {
  pdfUrl2: any;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      const nombreArchivo = params.get('name');
      if (nombreArchivo) {
        this.pdfUrl2 = nombreArchivo;
        // this.pdfUrl2 = `../../../../assets/documents/${nombreArchivo}.pdf`;
      }
    });
  }
}
