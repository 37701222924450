<div class="BG"  >
    <div class="socialMedia">
        <button  aria-label="_" class="btn-social" (click)="openMailInNewWindow('info@groovy.click')">
            <img width="1920" height= "1080" alt="MAIL" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/MAIL.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://www.sec.gov/edgar/browse/?CIK=1499275&owner=exclude')">
            <img width="1920" height= "1080" alt="SEC" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/SEC.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://www.otcmarkets.com/stock/SANP/overview')">
            <img width="1920" height= "1080" alt="OTC" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/OTC.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="FACEBOOK" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/FACEBOOK.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="INSTAGRAM" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/INSTAGRAM.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="WHATSAPP" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/WHATSAPP.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://www.reddit.com/r/GROOVYCompany/rising')">
            <img width="1920" height= "1080" alt="REDDIT" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/REDDIT.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://x.com/groovyclick')">
            <img width="1920" height= "1080" alt="X" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/X.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="YOUTUBE" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/YOUTUBE.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="SNAPCHAT" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/SNAPCHAT.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="TIKTOK" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/TIKTOK.webp">
        </button>
        <button  aria-label="_" class="btn-social"  (click)="openLinkInNewWindow('https://github.com/GroovyPlatform')">
            <img width="1920" height= "1080" alt="GIT" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/GIT.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://www.linkedin.com/company/groovyclick')">
            <img width="1920" height= "1080" alt="LINKEDIN" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/LINKEDIN.webp">
        </button>
    </div>
    <div class="socialContent">
        <p class="text">
            At the heart of this movement <br>
            is <b class="pink">Groovy commune</b>, where <br>
            like-minded individuals come <br>
            together to share <b>resources, <br>
            responsibilities, and ideals.</b>
        </p>
    </div>
    <div class="ELEMENT1">
        <img width="1920" height= "1080" alt="ELEMEN" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ELEMENT_1.webp">
    </div>
    <div class="ELEMENT2">
        <img width="1920" height= "1080" alt="ELEMEN2" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ELEMENT_2.webp">
    </div>
</div>