<div class="turnTableCont2">
    <div class="STICKERS2">
        <img width="1920" height= "1080" alt="sticker" class="img-fluid" src="/assets/images/home/GROOVY_P2P/STICKERS.webp" loading="lazy">
    </div>
    <div class="stick2">
        <img width="1920" height= "1080" alt="stick" class="img-fluid" src="/assets/images/hero/turntable/stick.svg" loading="lazy">
    </div>
    <div class="disk2">
        <img width="1920" height= "1080" alt="disk" class="img-fluid" src="/assets/images/hero/turntable/disk.svg" loading="lazy">
    </div>
    <div class="turnTable2">
        <span class="eyes"></span>
        <span class="eyes"></span>
    </div>
    <div class="bgWhite"></div>
    <div class="table2">
        <img width="1920" height= "1080" alt="table" class="img-fluid" src="/assets/images/home/GROOVY_P2P/TABLE.webp" loading="lazy">
    </div>
</div>