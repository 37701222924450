<div class="rollerCont">
    <div class="roller" (mouseenter)="activateSound()" (mouseleave)="deactivateSound()">
        <div class="rollerBraid">
            <img width="1920" height= "1080" alt="braids" src="/assets/images/hero/roller/braids.svg" class="img-fluid" loading="lazy">

        </div>
        <div>
            <div class="rueda1">
                <img width="1920" height= "1080" alt="wheel" src="/assets/images/hero/roller/rueda.svg" class="img-fluid" loading="lazy">
            </div>
            <div class="rueda2">
                <img width="1920" height= "1080" alt="wheel" src="/assets/images/hero/roller/rueda.svg" class="img-fluid" loading="lazy">
            </div>
            <div class="roller1">
                <img width="1920" height= "1080" alt="wheel" alt="Roller" src="/assets/images/hero/roller/roller1.svg" class="img-fluid" loading="lazy">
            </div>
        </div>
        <div class="roller2">
            <div class="rueda3">
                <img width="1920" height= "1080" alt="wheel" src="/assets/images/hero/roller/rueda.svg" class="img-fluid" loading="lazy">
            </div>
            <div class="rueda4">
                <img width="1920" height= "1080" alt="wheel" src="/assets/images/hero/roller/rueda.svg" class="img-fluid" loading="lazy">
            </div>
            <div class="roller2IMG">

                <img width="1920" height= "1080" alt="Roller" src="/assets/images/hero/roller/roller1.svg" class="img-fluid" loading="lazy">
            </div>
        </div>

    </div>

</div>