import { Component, EventEmitter, HostListener, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],

})
export class AboutUsComponent{
  slideIsActive: number = 0;
  @Output() navigate = new EventEmitter<number>();
  @Output() slideContact = new EventEmitter<number>();

  navigateToComponent(index: number, slide?: number) {
    this.navigate.emit(index);
    this.slideContact.emit(slide);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }

}
