<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" >  
   <div class="BG1">
        <div class="row">
            <div class="col-md">
                <div class="ELEMENT">
                    <img width="1920" height= "1080" alt="ELEMEN" loading="lazy" src="assets/images/home/GROOVY_REWARDS/SLIDE1/ELEMENT.webp" class="img-fluid">
                </div>
            </div>
            <div class="col-md">
                <div class="slideContent1">

                <p class="text">
                    Use the GROOVY app to scan the tags <br class="md-none">
                    on your cannabis products. <b> Each verified</b><br class="md-none">
                    scan earns you GROOVY points!  The  <br class="md-none">
                    more you scan, the more points you <br class="md-none">
                    earn, unlocking higher reward tiers and <br class="md-none">
                    exclusive perks.
                </p>
                <p class="text mt-2">   
                    <b>
                        Exchange your hard-earned points in <br class="md-none">
                        the GROOVY Marketplace for a wide <br class="md-none">
                        variety of exciting rewards:
                    </b>

                </p>
            </div>

            </div>
        </div>
        <button class="backButton" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
   </div>
    <div class="BG">
        <div class="row">
            <div class="col-md">
                <div class="title">
                    <img alt="title" width="1920" height= "1080" loading="lazy" src="assets/images/home/GROOVY_REWARDS/GROOVY_REWARDS2.webp" class="img-fluid">
                  </div>
                 <div class="slideContent">
                    <p class="text">
                        GROOVY isn't just about authentication; it's <br class="md-none"> 
                        a fun and rewarding experience! With our <br class="md-none">
                        unique gamification and rewards system, <br class="md-none">
                       <b> you'll be earning points and unlocking <br class="md-none">
                        exclusive benefits every time you scan <br class="md-none">
                        a GROOVY tag.</b>
                      </p>  
                 </div>
                 <div class="LayerMask">
                    <p class="text text-center">
                        Earn, Redeem, and Engage with the <br class="md-none">
                        <b>Cannabis Community</b>

                    </p>
                    <div class="buttonsContainer">
                        <button class="btn-slider"  (click)="handleSlideActivation(1)">
                            <div class="btnText">
                                <span>P</span>
                                <span>o</span>
                                <span>i</span>
                                <span>n</span>
                                <span>t</span>
                                <span>s</span>
                              </div>
                            <div class="star top-right"></div>
                            <div class="star bottom-left"></div>
                        </button>
                          <button class="btn-slider"  (click)="handleSlideActivation(2)">
                            <div class="btnText">
                                <span>T</span>
                                <span>r</span>
                                <span>u</span>
                                <span>s</span>
                                <span>t</span>
                              </div>
                            <div class="star top-left"></div>
                            <div class="star bottom-right"></div>  
                        </button>
                    </div>
                 </div>
            </div>
            <div class="col-md">
                <div class="elementsCont">
    
                <div class="movingTextCont" >
                    <div class="movingText2" style="animation-delay: 0.5s;">
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                    </div>
                    <div class="movingText">
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                    </div>
                    <div class="movingText2">
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                    </div>
                    <div class="movingText" style="animation-delay: 0.5s;">
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                        <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/REWARDS.webp"> 
                    </div>
                    
                </div>
                <div class="WOMAN">
                    <img width="1920" height= "1080" alt="WOMAN" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/WOMAN.webp"> 
                </div>
                <div class="STICKER">
                    <img width="1920" height= "1080" alt="STICKER" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/STICKER.webp"> 
                </div>
            </div>
    
              
            </div>
        </div>  
    </div>
    <div class="BG2">
        <div class="row">
            <div class="col-md">
                <div class="ELEMENT2">
                    <img width="1920" height= "1080" alt="ELEMENT2" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_REWARDS/SLIDE2/ELEMENT.webp"> 
                </div>
            </div>

            <div class="col-md">
                <div class="slideContent2">
                    <p class="text">
                        Whether you're a seasoned cannabis <br class="md-none">
                        connoisseur or just starting your journey,<br class="md-none">
                         GROOVY rewards everyone for their <br class="md-none">
                         participation in creating a <b>more transparent<br class="md-none">
                          and trustworthy</b> cannabis industry.
                    </p>

                </div>
            </div>
        </div>
        <button class="backButton2" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
    </div>
</div>

