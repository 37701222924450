<div class="ComponentCont" [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }">

  <div class="BG1">
    <div class="row">
      <div class="col-md-7">

        <div class="titleExclusiveMerchandise">
          <img width="1920" height= "1080" alt="whatToExpect" loading="lazy" src="assets/images/home/REWARDS_MARKETPLACE/EXCLUSIVE_MERCHANDISE/WHAT_TO_EXPECT.webp" class="img-fluid">
        </div>
        <div class="ele-1">
          <img width="1920" height= "1080" alt="elem1"  loading="lazy" src="assets/images/home/REWARDS_MARKETPLACE/EXCLUSIVE_MERCHANDISE/ELE_1.webp" class="img-fluid">

        </div>
        <div class="textExclusiveMerchandise">
          <p>
            <b>Exclusive Merchandise:</b> Redeem your GROOVY points<br>
            for stylish apparel, unique accessories, and gear <br>
            designed for the discerning cannabis enthusiast.
            <p>
              <p>
                <b>Partner Discounts:</b> Get access to special deals and <br>
                discounts from our network of trusted partners, inclu-<br>
                ding top-tier dispensaries, brands, and service provi-<br>
                ders.
              </p>

              <p>
                <b>Experiences & Events:</b> Use your points for access to <br>
                unforgettable cannabis-related events, educational<br>
                workshops, and curated experiences you won't find <br>
                anywhere else.
              </p>

              <p>
                <b>And Much More:</b> We're constantly adding new and <br>
                exciting rewards to keep the marketplace fresh and <br>
                engaging.
              </p>
        </div>
      </div>
      <div class="col-md BG_COL">
        <div class="OFF_40">
          <img width="1920" height= "1080" alt="off_40" loading="lazy" class="img-fluid" src="/assets/images/home/REWARDS_MARKETPLACE/40_OFF.webp">
        </div>
      </div>
    </div>
    <button class="backButton" (click)="handleSlideDesactivation(0)">
      BACK
    </button>
  </div>
  <div class="BG">
    <div class="BG_ELEMENTCONT">
      <div class="BG_ELEMENT">
        <img width="1920" height= "1080" alt="ELEM" loading="lazy" class="img-fluid" src="assets/images/home/REWARDS_MARKETPLACE/ELEMENT.webp">

      </div>
    </div>
    <div class="title">
      <img width="1920" height= "1080" alt="REWARDS_MARKETPLACE" loading="lazy" src="assets/images/home/REWARDS_MARKETPLACE/REWARDS_MARKETPLACE2.webp" alt="Movable Image" class="img-fluid">
    </div>

    <div class="slideContainer">

      <div class="INTRO">
        <div>
          <h2>Introducing</h2>
          <h2>the GROOVY</h2>
          <h2>Rewards Marketaplace</h2>
        </div>
        <div>
          <h2>Coming</h2>
          <h2>-</h2>
          <h2>Soon!</h2>
        </div>
      </div>
      <div class="slideContent">
        <p>
          Your loyalty is about to pay off in a big way. We're thrilled to an-<br>
          nounce the upcoming launch of the GROOVY Rewards Marketpla-<br>
          ce, where your points unlock a world of exclusive benefits.
        </p>

        <div class="buttonsContainer">
          <button class="btn-slider" id="exclusive" (click)="handleSlideActivation(1)">
            <div class="btnText">
              <span>E</span>
              <span>x</span>
              <span>c</span>
              <span>l</span>
              <span>u</span>
              <span>s</span>
              <span>i</span>
              <span>v</span>
              <span>e</span>
            </div>
            <div class="btnText second">
              <span>m</span>
              <span>e</span>
              <span>r</span>
              <span>c</span>
              <span>h</span>
              <span>a</span>
              <span>n</span>
              <span>d</span>
              <span>i</span>
              <span>s</span>
              <span>e</span>
            </div>
            <div class="star top-right"></div>
            <div class="star bottom-left"></div> 
          </button>
          <button class="btn-slider"  id="howToEarn" (click)="handleSlideActivation(2)">
            <div class="btnText">
              <span>H</span>
              <span>o</span>
              <span>w</span>
              <span class="blank"> </span>
              <span>t</span>
              <span>o</span>
            </div>
            <div class="btnText second" >
              <span>e</span>
              <span>a</span>
              <span>r</span>
              <span>n</span>
              <span class="blank"> </span>
              <span>p</span>
              <span>o</span>
              <span>i</span>
              <span>n</span>
              <span>t</span>
              <span>s</span>
            </div>
            <div class="star top-left"></div>
            <div class="star bottom-right"></div> 
          </button>
        </div>
      </div>
    </div>

  </div>

  <div class="BG2">
    <button class="backButton2" (click)="handleSlideDesactivation(0)">
      BACK
    </button>
    <div class="HOW_TO_EARN_POINTS_TITLE">
      <h2>How to</h2>
      <h2>earn</h2>
      <h2>points</h2>
    </div>
    <div class="row">
      <div class="col-md-5">
        <div class="elementsCont">

          <div class="movingTextCont">
            <div class="movingText">
              <p>REWARDS</p>
              <p>REWARDS</p>
              <p>REWARDS</p>
              <p>REWARDS</p>
              <p>REWARDS</p>
            </div>
            <div class="movingText2">
              <p>REWARDS</p>
              <p>REWARDS</p>
              <p>REWARDS</p>
                   <p>REWARDS</p>
              <p>REWARDS</p>
            </div>
            <div class="movingText">
              <p>REWARDS</p>
              <p>REWARDS</p>
              <p>REWARDS</p>
                   <p>REWARDS</p>
              <p>REWARDS</p>
            </div>

            <div class="movingText2">
              <p>REWARDS</p>
              <p>REWARDS</p>
              <p>REWARDS</p>
                   <p>REWARDS</p>
              <p>REWARDS</p>
            </div>

            <div class="movingText">
              <p>REWARDS</p>
              <p>REWARDS</p>
              <p>REWARDS</p>
                   <p>REWARDS</p>
              <p>REWARDS</p>
            </div>

          </div>
          <div class="ELE_REWARDS">
            <img width="1920" height= "1080" alt="HOW_TO_EARN" loading="lazy" class="img-fluid" src="/assets/images/home/REWARDS_MARKETPLACE/HOW_TO_EARN_POINTS/HOW_TO_EARN_POINTS_ELEMENT.webp">
          </div>
          <div class="ELE_2">
            <img width="1920" height= "1080" alt="ELE2" loading="lazy" class="img-fluid" src="/assets/images/home/REWARDS_MARKETPLACE/HOW_TO_EARN_POINTS/ELE_2.webp">
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="TEXT_HOW_TO_EARN_POINTS">
          <p>
            <b>Authenticate Products:</b> Scan GROOVY<br>
            tags to verify product authenticity and<br>
            earn points with every scan.
          </p>
          <p>
            <b>Leave Reviews:</b> Share your thoughts<br>
            and experiences with verified pro-<br>
            ducts to help others make informed<br>
            choices.
          </p>
          <p>
           <b>Participate in the Community:</b> Engage in<br>
            discussions, share content, and con-<br>
            tribute to the GROOVY community to<br>
            earn additional points.
          </p>
          </div>
      </div>
    </div>
  </div>