// src/app/services/country.service.ts
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countries: string[] = ["Åland Islands", "Afghanistan", "Akrotiri", "Albania", "Algeria", "American Samoa", 
    "Andorra", "Angola", "Anguilla", "Antarctica", "Antigua and Barbuda", "Argentina", 
    "Armenia", "Aruba", "Ashmore and Cartier Islands", "Australia", "Austria", "Azerbaijan", 
    "Bahrain", "Bangladesh", "Barbados", "Bassas Da India", "Belarus", "Belgium", "Belize", 
    "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Bouvet Island", 
    "Brazil", "British Indian Ocean Territory", "British Virgin Islands", "Brunei", "Bulgaria", 
    "Burkina Faso", "Burma", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", 
    "Caribbean Netherlands", "Cayman Islands", "Central African Republic", "Chad", "Chile", "China", 
    "Christmas Island", "Clipperton Island", "Cocos (Keeling) Islands", "Colombia", "Comoros", 
    "Cook Islands", "Coral Sea Islands", "Costa Rica", "Cote D'Ivoire", "Croatia", "Cuba", 
    "Curaçao", "Cyprus", "Czech Republic", "Democratic Republic of the Congo", "Denmark", "Dhekelia", 
    "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", 
    "Eritrea", "Estonia", "Ethiopia", "Europa Island", "Falkland Islands (Islas Malvinas)", "Faroe Islands", 
    "Federated States of Micronesia", "Fiji", "Finland", "France", "French Guiana", "French Polynesia", 
    "French Southern and Antarctic Lands", "Gabon", "Gaza Strip", "Georgia", "Germany", "Ghana", 
    "Gibraltar", "Glorioso Islands", "Greece", "Greenland", "Grenada", "Guadeloupe", "Guam", 
    "Guatemala", "Guernsey", "Guinea", "Guinea-bissau", "Guyana", "Haiti", "Heard Island and Mcdonald Islands", 
    "Holy See (Vatican City)", "Honduras", "Hong Kong", "Hungary", "Iceland", "India", "Indonesia", 
    "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Jan Mayen", "Japan", 
    "Jersey", "Jordan", "Juan De Nova Island", "Kazakhstan", "Kenya", "Kiribati", "Kosovo", "Kuwait", 
    "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", 
    "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", 
    "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", "Mauritius", "Mayotte", "Mexico", 
    "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Myanmar", 
    "Namibia", "Nauru", "Navassa Island", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia", 
    "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "North Korea", 
    "Northern Mariana Islands", "Norway", "Oman", "Pakistan", "Palau", "Palestine", "Panama", 
    "Papua New Guinea", "Paracel Islands", "Paraguay", "Peru", "Philippines", "Pitcairn Islands", "Poland", 
    "Portugal", "Puerto Rico", "Qatar", "Republic of the Congo", "Reunion", "Romania", "Russia", "Rwanda", 
    "Saint Barthélemy", "Saint Helena", "Saint Kitts and Nevis", "Saint Lucia", "Saint Martin", 
    "Saint Pierre and Miquelon", "Saint Vincent and the Grenadines", "Samoa", "San Marino", 
    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", 
    "Singapore", "Sint Maarten", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", 
    "South Georgia and the South Sandwich Islands", "South Korea", "South Sudan", "Spain", "Spratly Islands", 
    "Sri Lanka", "Sudan", "Suriname", "Svalbard", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", 
    "Tajikistan", "Tanzania", "Thailand", "The Bahamas", "The Gambia", "Timor-leste", "Togo", "Tokelau", 
    "Tonga", "Trinidad and Tobago", "Tromelin Island", "Tunisia", "Turkey", "Turkmenistan", "Turks and Caicos Islands", 
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", 
    "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Virgin Islands", "Wake Island", "Wallis and Futuna", 
    "West Bank", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"];
    private batchSize: number = 10;

    constructor() {}

    getCountries(): Observable<string[]> {
      return of(this.countries);
    }
  
    getTotalCountries(): number {
      return this.countries.length;
    }


}
