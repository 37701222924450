import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Output() navigate = new EventEmitter<number>();
  
  constructor() { }

  ngOnInit(): void {
  }
  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
  
  openLinkInNewWindow(url: string): void {
    window.open(url, '_blank');
  }
  openMailInNewWindow(email: string, subject?: string, body?: string): void {
    let mailtoLink = `mailto:${email}`;
    
    const params = [];
    if (subject) {
      params.push(`subject=${encodeURIComponent(subject)}`);
    }
    if (body) {
      params.push(`body=${encodeURIComponent(body)}`);
    }
  
    if (params.length > 0) {
      mailtoLink += `?${params.join('&')}`;
    }
  
    window.open(mailtoLink, '_blank');
  }

}
