import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserGateway } from "../models/User/gateway/user-gateway";
import { User } from "../models/User/user";

@Injectable ({
    providedIn : 'root'
})
export class UserUseCases {
    constructor( private _userGateway : UserGateway) {}
    create(user:User) :Observable<User> {
        return this._userGateway.create(user);
    }
    update(id:string, update: any) :Observable<User> {
        return this._userGateway.update(id, update);
    }
    getByID(id:string) :Observable<User> {
        return this._userGateway.getByID(id);
    }
}