<div class="loader-container" (click)="navigateToApp()" [ngClass]="{'disabled': !canSkip}">
  <div class="video-placeholder" *ngIf="isLoading">
    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/SPLASH.webp" alt="Loading...">
  </div>
    <video
    preload='auto'
    #videoPlayer1
    (canplay)="closebutton()"
    (ended)="onVideoEnded()"
    (timeupdate)="checkIfAlmostEnded($event.target)"
    [muted]="true"
    playsinline
    autoplay
    (loadeddata)="onVideoLoaded()"
    class="w-100 h-100"
  >
    <source src="assets/videos/SplashV8-min.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>

  <div class="lg-none md-none" id="videoSM" *ngIf="smShow">
    <video (canplay)="closebutton()" controls class="lg-none w-100">
      <source src="assets/videos/videoGroovyResponsive.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
  
</div>
