<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" >  
<div class="BG1">
    <div class="w-100 d-flex justify-content-center">

    <div class="titleOurMission">
        <img width="1920" height= "1080" loading="lazy" src="assets/images/home/ABOUT_US/OUR_MISSION/OUR_MISSION.webp" alt='ourMission'  class="img-fluid">
      </div>
    </div>

      <div class="row">
        <div class="col-md-7">
            <div class="slider-content1">
                <p class="text our_mission"> 
                    We're on a mission to revolutionize the<br> 
                    cannabis industry by leveraging the<br>
                    power of blockchain, secure tagging,<br>
                    and data-driven insights. Our goal is to<br>
                    eliminate counterfeits, provide <br>
                    unparalleled transparency, and empower<br>
                     every stakeholder in the cannabis<br>
                     ecosystem to make informed decisions.
                </p>
                <div class="d-flex">
                    <span class="ContactUSButton"  (click)="navigateToComponent(9,0)">
                    </span>
                </div>

                <div class="CANNABIS_TRANSFORM">
                    <img width="1920" height= "1080" loading="lazy" src="assets/images/home/ABOUT_US/OUR_MISSION/CANNABIS_TRANSFORM.webp"  alt='cannabisTransform' class="img-fluid">
                </div>
            </div>
          
        </div>
        <div class="col-md-5" id="OUR_MISSION_COL">
                <div class="crack">
                    <img width="1920" height= "1080" loading="lazy" src="assets/images/home/ABOUT_US/OUR_MISSION/CRACK.webp" alt='crack'  class="img-fluid">
                </div>
                <div class="element">
                    <img width="1920" height= "1080" loading="lazy" src="assets/images/home/ABOUT_US/OUR_MISSION/OUR_MISSION_ELEMENT.webp"  alt='ourMissionElement' class="img-fluid">
                </div>
                <div class="OUR_MISSION_MOVING_TEXT_CONT">
                    <div class="OUR_MISSION_MOVING_TEXT">
                        <p>TRANSFORM CANNABIS EXPERIENCE</p>      
                        <p>TRANSFORM CANNABIS EXPERIENCE</p>                     
                    </div>
                    <div class="OUR_MISSION_MOVING_TEXT2">
                        <p>TRANSFORM CANNABIS EXPERIENCE</p>      
                        <p>TRANSFORM CANNABIS EXPERIENCE</p>                     
                    </div>
                </div>
                
        </div>
        <button class="backButton" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
      </div>
</div>
    <div class="BG">
    <div class="row">
        <div class="col-md-6">
            <div class="title">
                <img width="1920" height= "1080" loading="lazy" src="assets/images/home/ABOUT_US/ABOUT_US.webp" alt="title" class="img-fluid">
              </div>
              <div class="">
                <div class="slider-content">
                  <p class="text text-center mt-4">
                    At GROOVY, we're more than just a <br>
                    technology platform <br>
                    – we're a community-driven movement<br>
                     dedicated to transforming the cannabis <br>
                     experience. We believe in empowering<br>
                     consumers with knowledge, supporting <br>
                     growers and manufacturers with <br>
                    innovative tools, and fostering a <br>
                    transparent marketplace where <br>
                    authenticity is paramount.
                  </p>


                
                </div>
                
            </div>
        </div>
        <div class="col-md">
            <div class="elementsCont">

            <div class="movingTextCont" >
                <div class="movingText">
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                </div>
                <div class="movingText2">
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                
                </div>
                <div class="movingText" style="animation-delay: 0.5s;">
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                    <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/GROOVY.webp" alt="movingText"> 
                
                </div>
                
            </div>
            <div class="cloud">
                <img width="1920" height= "1080" class="img-fluid" src="assets/images/home/ABOUT_US/CLOUD.webp" alt="cloud"> 
            </div>
            <div class="notes">
                <img width="1920" height= "1080" class="img-fluid" src="assets/images/home/ABOUT_US/NOTES2.webp" alt="notes"> 
            </div>
        </div>

          
        </div>
    </div>  
    <div class="mt-2 firstSlideFooter">

        <p class="text text-center">
            We envision a future where every cannabis product is verifiable, every
            consumer is empowered,<br> and every business is equipped with the tools
             to thrive in a transparent and trustworthy market.
        </p>
        <div class="buttonsContainer">
            <button class="btn-slider" id="our_mission"  (click)="handleSlideActivation(1)">
                <div class="btnText">
                    <span>o</span>
                    <span>u</span>
                    <span>r</span>
                    <span></span>
                    <span>m</span>
                    <span>i</span>
                    <span>s</span>
                    <span>s</span>
                    <span>i</span>
                    <span>o</span>
                    <span>n</span>
                  </div>
                <div class="star top-right"></div>
                <div class="star bottom-left"></div> 
            </button>
              <button class="btn-slider"  (click)="handleSlideActivation(2)">
                <div class="btnText">
                    <span>T</span>
                    <span>a</span>
                    <span>g</span>
                    <span>s</span>
               
                  </div>
                <div class="star top-left"></div>
                <div class="star bottom-right"></div>  
            </button>
        </div>

      </div>
</div>

<div class="BG2">
    <div class="row">
        <div class="col-md-6">
            <button class="backButton2" (click)="handleSlideDesactivation(0)">
                BACK
              </button>
            <div class="nftElement">
                <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/NFT/ELEMENT_NFT.webp" alt="ELEMENT_NFT">

            </div>
        </div>
        <div class="col-md" id="col-NFT">
            <p class="text">
                GROOVY combines state-of-the-art <br>
                technology with a user-friendly, engaging <br>
                platform. Our patented multi-layered <br>
                security tags, linked to blockchain-based<br>
                 NFTs, provide an ironclad seal of <br>
                 authenticity for every product. <br>
                 Our data-driven insights empower<br>
                 businesses to make informed <br>
                 decisions, while our community <br>
                 features foster connection, knowledge <br>
                 sharing, and collective growth.
                 <span class="ContactUSButton2"  (click)="navigateToComponent(9,0)">
                 </span>
            </p>
    
            <div class="d-flex">
                <div class="ALERT">
                    <img width="1920" height= "1080"  loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/NFT/ALERT.webp" alt="alert">
                </div>
                <div class="NFT">
                    <img width="1920" height= "1080"  loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/NFT/NFT.webp" alt="NFT">
                </div>
               <div class="LAYER">
                <img width="1920" height= "1080"  loading="lazy" class="img-fluid" src="assets/images/home/ABOUT_US/NFT/LAYER.webp" alt="Layer">
                </div>
            </div>
        
        </div>
    </div>
</div>
</div>