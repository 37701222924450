import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
// import { faApple, faFacebook, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { UserUseCases } from 'src/app/domain/usecase/userusecase';
import { AppleLoginProvider } from 'src/app/infraestructure/driven-adapter/apple/apple.provider';
import { v4 as uuidv4 } from 'uuid';
declare var AppleID: any;

declare global {
  interface Window {
    groovy: any; 
  }
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  user: SocialUser | any;
  loggedIn: boolean = false;
  authType: any;
  // fb = faFacebookF;
  // apple = faApple;
  @ViewChild('loginRef', {static: true }) loginElement!: ElementRef;
  
  constructor( private userUseCase: UserUseCases, private route: ActivatedRoute, private router: Router, private authService: SocialAuthService) {
  }
  private appleAuthEndpoint = 'https://appleid.apple.com/auth/authorize';
  private clientId = 'click.groovy.backend';
  private redirectURI = 'https://backend.groovy.click/user/auth/apple';
  private responseType = 'code id_token';
  private scope = 'email';
  private  AppleID: any;

  generateRandomState() {
    return uuidv4();
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
  }
  signInWithFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
  }

  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  
  public async signInWithApple () {
  try {
    console.log(AppleID);
    AppleID.auth.init({
      clientId: 'click.groovyweb',
      scope: 'name email',
      redirectURI:
        'https://groovy.click/apple-callback',
      state: 'init',
      nonce: 'test',
      usePopup: true, 
    });
    const data = await AppleID.auth.signIn();
      window.groovy.connect(this.parseJwt(data.authorization.id_token))
      this.userUseCase.create(this.parseJwt(data.authorization.id_token)).subscribe()
      setTimeout(() => {
        window.close();
    }, 2000);
  } catch (error) {
    console.log(error);
   
  }
   }
  toProfile(): void {
    this.router.navigate(['/profile'])
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.authType = params['type'];
    });

    this.authService.authState.subscribe((user) => {
      if(user !== undefined|| null|| ''){
        window.groovy.connect(user)
        this.userUseCase.create(user).subscribe()
        setTimeout(() => {
          window.close();
      }, 2000);
      } 
      this.user = user;
      this.loggedIn = (user != null);
    })
  }

}
