<div class="BG">
  <div class="FooterContainer">
    <div class="BGElement">
      <img width="1920" height= "1080" alt="BG_FOOTER" loading="lazy" class="img-fluid" src="/assets/images/home/FOOTER/BG_FOOTER2.webp">
    </div>
      <div class="row">
          <div class="col-md-9">
            <div class="textCont">
                <p class="">
                    <b> ABOUT GROOVY</b>
                </p>
              <p class="">
            
                <span>OVERVIEW </span>| <span>ANNUAL REPORT</span> | <span (click)="openLinkInNewWindow('https://zfrmz.com/cbs9eDZbdKLbt4IK2qpA')">CORPORATE SOCIAL RESPONSIBILITY</span> |<br>
                <span (click)="openLinkInNewWindow('https://zfrmz.com/UaIUFhRpROsMpnqEtT3k')">DIVERSITY & INCLUSION </span>| <span>FINANCING </span>| <span (click)="navigateToComponent(12)">INVESTOR</span> | <span (click)="openLinkInNewWindow('http://www.einpresswire.com/newsroom/groovy_news/')">NEWSROOM </span>| <span (click)="openLinkInNewWindow('https://zfrmz.com/qoIgVdujg50UqM6ar1fs')">SECURITY,<br>
                PRIVACY & TRUST </span>|<span (click)="openLinkInNewWindow('https://zfrmz.com/1pTZrFSxLJNovWUKh2FC')"> SENIOR LEADERSHIP </span>| <span (click)="openLinkInNewWindow('https://zfrmz.com/DV6SNxQTrcJkHGgvKhGb')">CAREERS WITH GROOVY</span>

              </p>
              <p class="">
               <b><span (click)="openLinkInNewWindow('https://zfrmz.com/4k83QCwvOToEP7qZOmJy')">GROOVY PRIVACY NOTICE</span>| <span (click)="openLinkInNewWindow('https://zfrmz.com/B0gN37OiN4aMrBSxAOtG')">GROOVY COOKIES</span>| <span (click)="openLinkInNewWindow('https://zfrmz.com/VQcqZskdeMy7rlNkng1U')">TERMS OF USE</span> |<br class="lg-none"> <span (click)="openLinkInNewWindow('https://zfrmz.com/PmQV7hUAjgbXxHEZgZOx')">DO NOT SHARE MY<br class="md-none">
                DATA </span> © 2024 Groovy Company, Inc. All rights reserved.</b><br><br>
                
                Individual results within the Groovy Company program may vary. Please contact us for details.<br><br>
                
                Any testimonials, statements, and opinions are applicable only to the individuals depicted. Testimonials<br>
                were provided voluntarily. Participants were not paid or provided with any benefits in exchange for their<br>
                 statements.
                <br><br>
              </p>
            </div>
          </div>
          <div class="col-md">

          </div>
      </div>
  </div>
  <div class="socialMedia">
    <button class="btn-social" aria-label="mail" (click)="openMailInNewWindow('info@groovy.click')">
        <img width="1920" height= "1080" alt="MAIL" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/MAIL.webp">
    </button>
    <button class="btn-social" aria-label="sec" (click)="openLinkInNewWindow('https://www.sec.gov/edgar/browse/?CIK=1499275&owner=exclude')">
        <img width="1920" height= "1080" alt="SEC" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/SEC.webp">
    </button>
    <button class="btn-social" aria-label="otc" (click)="openLinkInNewWindow('https://www.otcmarkets.com/stock/SANP/overview')">
        <img width="1920" height= "1080" alt="OTC" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/OTC.webp">
    </button>
    <button class="btn-social" aria-label="facebook">
        <img width="1920" height= "1080" alt="FACEBOOK" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/FACEBOOK.webp">
    </button>
    <button class="btn-social" aria-label="instagram">
        <img width="1920" height= "1080" alt="INSTAGRAM" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/INSTAGRAM.webp">
    </button>
    <button class="btn-social" aria-label="whatsapp">
        <img width="1920" height= "1080" alt="WHATSAPP" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/WHATSAPP.webp">
    </button>
    <button class="btn-social" aria-label="reddit" (click)="openLinkInNewWindow('https://www.reddit.com/r/GROOVYCompany/')">
        <img width="1920" height= "1080" alt="REDDIT" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/REDDIT.webp">
    </button>
    <button class="btn-social" aria-label="x" (click)="openLinkInNewWindow('https://x.com/groovyclick')">
        <img width="1920" height= "1080" alt="X" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/X.webp">
    </button>
    <button class="btn-social" aria-label="youtube">
        <img width="1920" height= "1080" alt="YOUTUBE" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/YOUTUBE.webp">
    </button>
    <button class="btn-social" aria-label="snapchat">
        <img width="1920" height= "1080" alt="SNAPCHAT" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/SNAPCHAT.webp">
    </button>
    <button class="btn-social" aria-label="tiktok">
        <img width="1920" height= "1080" alt="TIKTOK" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/TIKTOK.webp">
    </button>
    <button class="btn-social" aria-label="git" (click)="openLinkInNewWindow('https://github.com/GroovyPlatform')">
        <img width="1920" height= "1080" alt="GIT" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/GIT.webp">
    </button>
    <button class="btn-social" aria-label="linkedin" (click)="openLinkInNewWindow('https://www.linkedin.com/company/groovyclick')">
      <img width="1920" height= "1080" alt="LINKEDIN" loading="lazy" class="img-fluid" src="/assets/images/home/SOCIAL_MEDIA/ICONS/LINKEDIN.webp">
  </button>
</div>
<div class="FOOTER_ELEMENT">
  <div class="rollers">
      <img width="1920" height= "1080" alt="ELEMENT_FOOTER" loading="lazy" class="img-fluid" src="/assets/images/home/FOOTER/ELEMENT_FOOTER3.webp">
  </div>
  <div class="roller1">
    <div class="wheel">
        <img width="1920" height= "1080" alt="WHEEL" loading="lazy" class="img-fluid" src="/assets/images/home/FOOTER/WHEEL.webp">
      </div>
      <div class="wheel">
        <img width="1920" height= "1080" alt="WHEEL" loading="lazy" class="img-fluid" src="/assets/images/home/FOOTER/WHEEL.webp">
      </div>
  </div>

  <div class="roller2">
    <div class="wheel">
        <img width="1920" height= "1080" alt="WHEEL" loading="lazy" class="img-fluid" src="/assets/images/home/FOOTER/WHEEL.webp">
      </div>
      <div class="wheel">
        <img width="1920" height= "1080" alt="WHEEL" loading="lazy" class="img-fluid" src="/assets/images/home/FOOTER/WHEEL.webp">
      </div>
  </div>

</div>

</div>
<div class="lastBar">
  <span class="text-white"> Designed by: <div class="LOGO" (click)="openLinkInNewWindow('https://cibernacion.com.co')">
    <img width="1920" height= "1080" alt="LOGO_CIBERNACION" loading="lazy" class="img-fluid" src="/assets/images/home/FOOTER/logo_cibernacion_blanco.webp">
  </div></span>
 
</div>