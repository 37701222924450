<div class="ComponentCont" [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }">
<div class="BG1" >
    <div class="header row">
            <div class="col-md" style="justify-content: center;">
                <div class="POSITION_PROGRESS">
                    <h3 class="text-center" [innerHTML]="form === 0 ? 'Basic Details': 'Additional Information'"></h3>
                    <div class="SLIDE_PROGRESS">
                        <span>1</span>
                        <span></span>
                        <span [ngStyle]="{ 'background-color': form === 0 ? 'initial' : '#b1d5b4' }">2</span>
    
                    </div>
                </div>
            </div>
            <div class="col-md">
                <div class="contactUSTitle">
                    <img width="1920" height= "1080" loading="lazy" src="assets/images/home/CONTACT_US/SLIDE1/CONTACT_US2.webp" alt="Movable Image" class="img-fluid">
                  </div>
            </div>
        
    </div>
    <div class="formGrid">
        <div>
            <div class="PAPERS">
                <img width="1920" height= "1080" alt="PAPERS" loading="lazy" class="img-fluid" src="assets/images/home/CONTACT_US/SLIDE1/PAPERS2.webp"> 
            </div>
        </div>
        <div class="overflow-hidden">
            <app-form (formChange)="changeForm($event)"></app-form>

        </div>
</div>
<button class="backButton" (click)="handleSlideDesactivation(0)">
    BACK
  </button>
</div>
<div class="BG"  >
    <div class="title">
        <img width="1920" height= "1080" loading="lazy" src="assets/images/home/CONTACT_US/CONTACT_US2.webp" alt="Movable Image" class="img-fluid">
      </div>
    <div class="row">
        <div class="col-md-6 align-content-center">  
        <p class="text">
            Groovy is revolutionizing the cannabis<br> 
industry with its innovative platform that <br>
combines cutting-edge technology with a <br>
nostalgic 70s vibe. We prioritize trust and <br>
transparency, empowering consumers and <br>
businesses alike. Our blockchain-based <br>
solutions ensure product authenticity, while <br>
our data analytics provide valuable insights <br>
to drive informed decision-making. Join <br>
our vibrant community and experience the<br>
 future of cannabis with Groovy. Contact us <br>
 to learn more about our products, services, <br>
 and partnership opportunities.<br><br>
 <span class="mailto" (click)="openMailInNewWindow('info@groovy.click')">Info@groovy.click</span>

        </p>
        </div>
        <div class="col-md">
    <div class="PHONE">
        <img width="1920" height= "1080" alt="PHONE" loading="lazy" class="img-fluid" src="assets/images/home/CONTACT_US/PHONE2.webp"> 
    </div>
    <div class="CONTACT_TEXT">
        <img width="1920" height= "1080" alt="CONTACT_TEXT" loading="lazy" class="img-fluid" src="assets/images/home/CONTACT_US/CONTACT.webp"> 
    </div>
        </div>
    </div>  
    
    <div class="buttonsContainer">
        <button class="btn-slider"  (click)="handleSlideActivation(1)">
          <div class="btnText">
            <span>M</span>
            <span>a</span>
            <span>i</span>
            <span>l</span>
            <span class="blank"></span>
            <span>t</span>
            <span>o</span>
         
          </div>
          <div class="star top-right"></div>
          <div class="star bottom-left"></div> 
        </button>
        <button class="btn-slider"  (click)="handleSlideActivation(2)">
          <div class="btnText">
            <span>G</span>
            <span>r</span>
            <span>o</span>
            <span>o</span>
            <span>v</span>
            <span>y</span>
          </div>
          <div class="star top-left"></div>
          <div class="star bottom-right"></div> 
        </button>
      </div>
</div>
<div class="BG2">
    <div class="textSlide">
        <p class="text-center">
            <b>Groovy Company, Inc.</b><br>
300 Peachtree Street NE<br>
Ste CS2 #1775<br>
Atlanta, GA 30308-3565<br>
1-(844) 9GROOVY <br>
info@groovy.click

        </p>
    </div>
    <div class="slideElements">
        <button  aria-label="_" class="cardCont">
            <div class="cardImg" (click)="openLinkInNewWindow('https://zfrmz.com/1pTZrFSxLJNovWUKh2FC')">
        <img width="1920" height= "1080" alt="TEAM" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/TEAM.webp"> 
            </div>
        </button>
        <button  aria-label="_" class="cardCont">
            <div class="cardImg"  (click)="openLinkInNewWindow('https://zfrmz.com/DV6SNxQTrcJkHGgvKhGb')">
                <img width="1920" height= "1080" alt="CAREERS" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/CAREERS.webp"> 

                </div>
            </button>
            <button  aria-label="_" class="cardCont">
                <div class="star">
                    <img width="1920" height= "1080" alt="STAR" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/STAR.webp"> 
                    
                </div>
                <div class="cardImg"  (click)="navigateToComponent(11)">
                    <img width="1920" height= "1080" alt="INVESTORS" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/INVESTORS.webp"> 

                </div>
                </button>
    </div>
    <div class="socialMedia">
        <button  aria-label="_" class="btn-social" (click)="openMailInNewWindow('info@groovy.click')">
            <img width="1920" height= "1080" alt="MAIL" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/MAIL.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://www.sec.gov/edgar/browse/?CIK=1499275&owner=exclude')">
            <img width="1920" height= "1080" alt="SEC" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/SEC.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://www.otcmarkets.com/stock/SANP/overview')">
            <img width="1920" height= "1080" alt="OTC" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/OTC.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="FACEBOOK" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/FACEBOOK.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="INSTAGRAM" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/INSTAGRAM.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="WHATSAPP" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/WHATSAPP.webp">
        </button>
        <button  aria-label="_" class="btn-social"(click)="openLinkInNewWindow('https://www.reddit.com/r/GROOVYCompany/')" >
            <img width="1920" height= "1080" alt="REDDIT" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/REDDIT.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://x.com/groovyclick')">
            <img width="1920" height= "1080" alt="X" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/X.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="YOUTUBE" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/YOUTUBE.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="SNAPCHAT" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/SNAPCHAT.webp">
        </button>
        <button  aria-label="_" class="btn-social">
            <img width="1920" height= "1080" alt="TIKTOK" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/TIKTOK.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://github.com/GroovyPlatform')">
            <img width="1920" height= "1080" alt="GIT" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/GIT.webp">
        </button>
        <button  aria-label="_" class="btn-social" (click)="openLinkInNewWindow('https://www.linkedin.com/company/groovyclick')">
            <img width="1920" height= "1080" alt="LINKEDIN" loading="lazy" class="img-fluid" src="/assets/images/home/CONTACT_US/SLIDE2/ICONS/LINKEDIN.webp">
        </button>
    </div>
    <button class="backButton2" (click)="handleSlideDesactivation(0)">
        BACK
      </button>
</div>

</div>
