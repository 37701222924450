import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private muteSubject = new BehaviorSubject<boolean>(false);
  mute$ = this.muteSubject.asObservable();

  toggleMute() {
    this.muteSubject.next(!this.muteSubject.value);
  }

  setMute(mute: boolean) {
    this.muteSubject.next(mute);
  }

  isMuted(): boolean {
    return this.muteSubject.value;
  }
}
