import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-web3',
  templateUrl: './web3.component.html',
  styleUrls: ['./web3.component.css']
})
export class WEB3Component {
  slideIsActive: number = 0;
  @Output() navigate = new EventEmitter<number>();

  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }
}
