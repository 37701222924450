import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {
  constructor(private elementRef: ElementRef) { }
  slideIsActive: number = 0;
  @Output() navigate = new EventEmitter<number>();
  @Input() currentContactSlide: number = 1;
  @Output() slideContact = new EventEmitter<number>();
  private offsetY: number = 0;
  private isDown: boolean = false;
  form: number = 0;

  changeForm(number: number){
    this.form = number
  }
  openMailInNewWindow(email: string, subject?: string, body?: string): void {
    let mailtoLink = `mailto:${email}`;
    
    const params = [];
    if (subject) {
      params.push(`subject=${encodeURIComponent(subject)}`);
    }
    if (body) {
      params.push(`body=${encodeURIComponent(body)}`);
    }
  
    if (params.length > 0) {
      mailtoLink += `?${params.join('&')}`;
    }
  
    window.open(mailtoLink, '_blank');
  }  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentContactSlide']) {
      if (this.currentContactSlide === 0) {
        this.handleSlideActivation(1);
      } else if (this.currentContactSlide !== 0) {
        this.handleSlideDesactivation(0);
      }
    }
  }
  openLinkInNewWindow(url: string): void {
    window.open(url, '_blank');
  }

  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }
  
  ngOnInit(): void {
    if(this.currentContactSlide === 0){
      this.handleSlideActivation(0)
    }else if(this.currentContactSlide !== 0){
      this.handleSlideDesactivation(0)
    }
  }
}
