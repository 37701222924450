<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" >  
<div class="BG1">
  <div class="row">
    <div class="col-md">

    </div>
    <div class="col-md-7">
      <div class="slideContent1">
        <p class="text">
          3D Holographic Shield: A visually stunning,<br>
          tamper-evident hologram that's nearly<br>
          impossible to replicate, creating a powerful first<br>
          line of defense against counterfeits.
        </p>
        <p class="text">
          Micro-Dot Encryption: Microscopic patterns,<br>
          invisible to the naked eye, are encoded into the<br>
          tag, adding another layer of complexity that<br>
          deters illicit replication.
        </p>
        <p class="text">
          Unique NFT QR Code: The heart of the tag is a<br>
          unique QR code linked to a digital Non-Fungible<br>
          Token (NFT) on a secure blockchain. This NFT<br>
          acts as a digital certificate of authenticity,<br>
           storing verified information about the product's<br>
            origin, strain, and testing results.
        </p>
      </div>
    </div>
  </div>
  <button class="backButton" (click)="handleSlideDesactivation(0)">
    BACK
  </button>
</div>
<div class="BG"  >
    <div class="row">
        <div class="col-md">
            <div class="CAN">
                <img width="1920" height= "1080" alt="CAN" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_TAGS/ELEMENT_GROOVY_TAG.webp"> 
            </div>
            <div class="STICKER">
                <img width="1920" height= "1080" alt="STICKER" loading="lazy" class="img-fluid" src="assets/images/home/GROOVY_TAGS/HIGH_VIBE.webp"> 
            </div>
        </div>
        <div class="col-md-6">  
            <div class="title">
                <img width="1920" height= "1080" alt="GROOVY_TAGS" loading="lazy" src="assets/images/home/GROOVY_TAGS/GROOVY_TAGS.webp" alt="Movable Image" class="img-fluid">
              </div>
              <div class="slideContent">
                <p class="text">
                  GROOVY's security tags are more than just a <br>
                  sticker – they're a fortress of cutting-edge <br>
                  technology designed to protect the integrity <br>
                  of your cannabis products and empower you <br>
                  with information.
                </p> 
                <div class="multi_layered">
                  <img width="1920" height= "1080" alt="multi-layered" loading="lazy" class="img-fluid" src="/assets/images/home/GROOVY_TAGS/MULTI_LAYERED.webp">
                </div>
              </div>
          
        </div>
     
    </div>  
    <div class="maskLayered">
      <div class="buttonsContainer">
        <button class="btn-slider" id="tag_tech"  (click)="handleSlideActivation(1)">
          <div class="btnText">
            <span>T</span>
            <span>a</span>
            <span>g</span>
            <span class="blank"></span>
            <span>t</span>
            <span>e</span>
            <span>c</span>
            <span>h</span>
          </div>
            <div class="star top-right"></div>
            <div class="star bottom-left"></div>
          </button>
          <button class="btn-slider" id="scan"  (click)="handleSlideActivation(2)">
            <div class="btnText">
              <span>S</span>
              <span>c</span>
              <span>a</span>
              <span>n</span>
            </div>
            <div class="star top-left"></div>
            <div class="star bottom-right"></div>
          </button>
    </div>
      <p class="maskText">
      Each tag is a masterpiece of anti-counterfeiting measures,<br>
      seamlessly integrated into a single, user-friendly experience:<br>
    </p>
    </div>
</div>
<div class="BG2">
  <div class="row">
    <div class="col-md">
      </div>
      <div class="col-md-6">
        <div class="slideContent2">
          <p class="text">
            Strain Details at Your Fingertips: Get ins-<br>
            tant access to comprehensive strain in-<br>
            formation, including cannabinoid and<br>
            terpene profiles, grower details, and lab<br>
            results, empowering you to make infor-<br>
            med choices.
          </p>
          <p class="text">
            Earn Rewards, Build Community: Each<br>
            authentic scan earns you GROOVY<br>
            points, redeemable for exclusive rewards<br>
            and discounts. It's our way of thanking<br>
            you for participating in the movement<br>
            towards transparency and trust.
          </p>
        </div>
      </div>
  </div>
  <button class="backButton2" (click)="handleSlideDesactivation(0)">
    BACK
  </button>
</div>
</div>