import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  ngOnInit(): void {
  }

  menuActive: boolean = false;
  @Output() menuActivated = new EventEmitter<void>();
  @Output() menuOff = new EventEmitter<void>();
  @Output() navigate = new EventEmitter<number>(); 


  activeLink: string = '';

  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
   onMenuClick = async( boolean: boolean, index: number) =>{
    this.menuActive = boolean
    if(boolean === true){
      this.menuActivated.emit();
    }else if(boolean === false){
      if(index !== 0){
        this.navigateToComponent(index)
      }
      this.menuOff.emit();
    }
      // window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  getImageSrc(linkId: string): string {
    if (this.activeLink && this.activeLink !== linkId) {
      return `assets/images/menu/${linkId}_dimmed.webp`;
    } else {
      return `assets/images/menu/${linkId}.webp`;
    }
  }
  getClass(linkId: string): string {
    if (this.activeLink && this.activeLink !== linkId) {
      return 'dimmed';
    } else {
      return '';
    }
  }
}
