import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-our-tech',
  templateUrl: './our-tech.component.html',
  styleUrls: ['./our-tech.component.css']
})
export class OurTechComponent implements OnInit {
  slideIsActive: number = 0;
  @Output() navigate = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }
  
  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }
  

}
