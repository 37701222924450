import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TurntableComponent } from './turntable/turntable.component';
import { GroovyP2pComponent } from './groovy-p2p.component';



@NgModule({
  declarations: [
    TurntableComponent,
    GroovyP2pComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [TurntableComponent, GroovyP2pComponent]
})
export class GroovyP2pModule { }
