<div class="angel" (mouseenter)="activateSound()" (mouseleave)="deactivateSound()">
    <div class="head">
        <div class="hair">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>


        </div>
        <div class="face">
            <div class="eyes">
                <span></span>
                <span></span>
            </div>
            <div class="eyes">
                <span></span>
                <span></span>
            </div>
            <span class="smile">
            </span>
        </div>
    </div>
    <div class="body">
        <div class="hands">
            <span></span>
            <span></span>
        </div>
        <div class="heart">
            <img width="1920" height= "1080" alt="heart" class="img-fluid" src="assets/images/hero/angel/heart.webp" loading="lazy">
        </div>
    </div>
</div>