import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadService {
  constructor(private resolver: ComponentFactoryResolver) {}

  loadComponent(viewContainerRef: ViewContainerRef, component: any) {
    const factory = this.resolver.resolveComponentFactory(component);
    viewContainerRef.clear();
    viewContainerRef.createComponent(factory);
  }
}
