import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DefaultComponent } from "./UI/layouts/default/default.component";
import { AuthComponent } from "./UI/modules/auth/auth.component";
import { ProfileComponent } from "./UI/modules/profile/profile.component";
import { LoginComponent } from "./UI/modules/login/login.component";
import { DocumentComponent } from "./UI/shared/components/document/document.component";

const routes: Routes = [
  {
    path: "",
    component: DefaultComponent,
    children: [

      {
        path: "profile",
        component: ProfileComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
    ],
  },
  {
    path: 'document/:name',
    component: DocumentComponent,
  },
  {
    path: 'auth/:type',
    component: AuthComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
