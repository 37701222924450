import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './contact-us.component';
import { FormComponent } from './form/form.component';
import { CountryService } from 'src/app/infraestructure/driven-adapter/services/country.service';



@NgModule({
  declarations: [ContactUsComponent, FormComponent],
  exports: [ContactUsComponent],
  imports: [
    CommonModule
  ],
  providers: [CountryService]
})
export class ContactUsModule { }
