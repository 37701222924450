import { Component, EventEmitter, Output } from '@angular/core';
import { CountryService } from 'src/app/infraestructure/driven-adapter/services/country.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  @Output() navigate = new EventEmitter<number>();
  @Output() formChange = new EventEmitter<number>();
  countries: string[] = [];
  countriesLoaded = false;
  
  constructor(private countryService: CountryService) {}
  
  slideIsActive: number = 0;
  
  loadCountries(): void {
    if (!this.countriesLoaded) {
      this.countryService.getCountries().subscribe(countries => {
        this.countries = countries;
        this.countriesLoaded = true;
      });
    }
  }

  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
    this.formChange.emit(number)
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
    this.formChange.emit(number)
  }
  
}
