import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-affiliates-and-partners',
  templateUrl: './affiliates-and-partners.component.html',
  styleUrls: ['./affiliates-and-partners.component.css']
})
export class AffiliatesAndPartnersComponent {
  slideIsActive: number = 0;
  @Output() navigate = new EventEmitter<number>();
  @Output() slideContact = new EventEmitter<number>();

  navigateToComponent(index: number, slide?: number) {
    this.navigate.emit(index);
    this.slideContact.emit(slide);
  }

  openLink(url: string): void {
    window.open(url);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }

 
}
