import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { CountdownComponent } from './components/countdown/countdown.component';
import { MenuComponent } from './components/menu/menu.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { DocumentComponent } from './components/document/document.component';
import { DocumentReaderComponent } from './components/document-reader/document-reader.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CountdownComponent,
    MenuComponent,
    BackToTopComponent,
    DocumentComponent,
    DocumentReaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports : [
    HeaderComponent,
    FooterComponent,
    CountdownComponent,
    BackToTopComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
