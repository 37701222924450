<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" > 
    <div class="BG1">
        <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md">
                <div class="SLIDE1_ELEMENTS">
                    <img alt="ELEMENTS" width="1920" height= "1080" loading="lazy" class="img-fluid" src="/assets/images/home/GROOVY_LIBRARY/SLIDE1/ELEMENTS_SLIDE1.webp">
                </div>
                <div class="slideContent1">
                    <p class="text">
                        Breeders: Create a unique NFT for each<br class="md-none">
                        original Geno-clone.
                    </p>
                    <p class="text">
                        Sub-Cultivators: Register sliced clones on the<br class="md-none">
                        blockchain, linking them to the original<br class="md-none">
                        NFT, update geolocation data for additional<br class="md-none">
                         Groovy rewards.
                    </p>
                    <p class="text">
                        Consumers: Verify authenticity and lineage<br class="md-none">
                        through the Groovy app.
                    </p>  
                </div>
            </div>

        </div>
        <button class="backButton" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
    </div>    
    <div class="BG">
        <div class="row">
            <div class="col-md">
                <div class="slideContent">
                    
                <div class="title">
                    <img alt="title" width="1920" height= "1080" loading="lazy" class="img-fluid" src="/assets/images/home/GROOVY_LIBRARY/TITLE.webp">
                </div>
                <p class="text">
                    Groovy's Geo-Clone NFT is<br class="md-none">
                    revolutionizing the cannabis genetics <br class="md-none">
                    industry by offering secure and<br class="md-none">
                    transparent tracking of clones from seed<br class="md-none">
                    to sale, along with exclusive access to a <br class="md-none">
                    verified geno-clone library on the <br class="md-none">
                    blockchain. Built on blockchain <br class="md-none">
                    technology, each original geno-clone is <br class="md-none">
                    assigned a unique NFT containing<br class="md-none">
                     geolocation data and genetic information, <br class="md-none">
                     all securely stored on the blockchain.
                </p>
                <div class="buttonsContainer">
                    <button class="btn-slider"  (click)="handleSlideActivation(1)">
                      <div class="btnText">
                        <span>W</span>
                        <span>i</span>
                        <span>n</span>
                        <span class="blank">-</span>
                        <span>W</span>
                        <span>i</span>
                        <span>n</span>
                     
                      </div>
                      <div class="star top-right"></div>
                      <div class="star bottom-left"></div> 
                    </button>
                    <button class="btn-slider"  (click)="handleSlideActivation(2)">
                      <div class="btnText">
                        <span>N</span>
                        <span>F</span>
                        <span>T</span>
                        <span class="blank">-</span>
                        <span>D</span>
                        <span>N</span>
                        <span>A</span>
                      </div>
                      <div class="star top-left"></div>
                      <div class="star bottom-right"></div> 
                    </button>
                  </div>
                </div>
            
            </div>
            <div class="col-md">
                <p class="text">
                    This ensures breeders<br>
                    receive royalties on every<br>
                    subsequent sale of their<br>
                    clone's offspring, while<br>
                    incentivizing sub-cultivators<br>
                    to register sliced clones and<br>
                    update geolocation data for <br>
                    rewards in Groovy tokens.
                </p>
                <div class="ELEMENT">
                    <img alt="CHAIR" width="1920" height= "1080" loading="lazy" class="img-fluid" src="/assets/images/home/GROOVY_LIBRARY/CHAIR_ELEMENT@2x.webp">
                </div>
            </div>
        
    </div>

    </div>    
    <div class="BG2">
        <div class="row">
            <div class="col-md-7">
                <div class="DNACONTAINER">
                    <div class="movingTextCont">
                        <div class="movingText">
                          <p>NFT</p>
                          <p>NFT</p>
                          <p>NFT</p>
                          <p>NFT</p>
                          <p>NFT</p>
                        </div>
                        <div class="movingText2">
                          <p>NFT</p>
                          <p>NFT</p>
                          <p>NFT</p>
                          <p>NFT</p>
                          <p>NFT</p>
                        </div>
                        <div class="movingText">
                          <p>NFT</p>
                          <p>NFT</p>
                          <p>NFT</p>
                        <p>NFT</p>
                          <p>NFT</p>
                        </div>
            
                      </div>
                    <div class="dna" style="left: -45%;">
                      <div style="--nth-child: 1;"></div>
                      <div style="--nth-child: 2;"></div>
                      <div style="--nth-child: 3;"></div>
                      <div style="--nth-child: 4;"></div>
                      <div style="--nth-child: 5;"></div>
                      <div style="--nth-child: 6;"></div>
                      <div style="--nth-child: 7;"></div>
                      <div style="--nth-child: 8;"></div>
                      <div style="--nth-child: 9;"></div>
                      <div style="--nth-child: 10;"></div>
                      <div style="--nth-child: 11;"></div>
                      <div style="--nth-child: 12;"></div>
                      <div style="--nth-child: 13;"></div>
                      <div style="--nth-child: 14;"></div>
                      <div style="--nth-child: 15;"></div>
                      <div style="--nth-child: 16;"></div>
                      <div style="--nth-child: 17;"></div>
                      <div style="--nth-child: 18;"></div>
                      <div style="--nth-child: 19;"></div>
                      <div style="--nth-child: 20;"></div>
                      <div style="--nth-child: 21;"></div>
                      <div style="--nth-child: 22;"></div>
                      <div style="--nth-child: 23;"></div>
                      <div style="--nth-child: 24;"></div>
                      <div style="--nth-child: 25;"></div>
                      <div style="--nth-child: 26;"></div>
                      <div style="--nth-child: 27;"></div>
                      <div style="--nth-child: 28;"></div>
                      <div style="--nth-child: 29;"></div>
                      <div style="--nth-child: 30;"></div>
                      <div style="--nth-child: 31;"></div>
                      <div style="--nth-child: 32;"></div>
                      <div style="--nth-child: 33;"></div>
                      </div>
                     <div class="dna" style="left: -15%;">
                      <div style="--nth-child: 1;"></div>
                      <div style="--nth-child: 2;"></div>
                      <div style="--nth-child: 3;"></div>
                      <div style="--nth-child: 4;"></div>
                      <div style="--nth-child: 5;"></div>
                      <div style="--nth-child: 6;"></div>
                      <div style="--nth-child: 7;"></div>
                      <div style="--nth-child: 8;"></div>
                      <div style="--nth-child: 9;"></div>
                      <div style="--nth-child: 10;"></div>
                      <div style="--nth-child: 11;"></div>
                      <div style="--nth-child: 12;"></div>
                      <div style="--nth-child: 13;"></div>
                      <div style="--nth-child: 14;"></div>
                      <div style="--nth-child: 15;"></div>
                      <div style="--nth-child: 16;"></div>
                      <div style="--nth-child: 17;"></div>
                      <div style="--nth-child: 18;"></div>
                      <div style="--nth-child: 19;"></div>
                      <div style="--nth-child: 20;"></div>
                      <div style="--nth-child: 21;"></div>
                      <div style="--nth-child: 22;"></div>
                      <div style="--nth-child: 23;"></div>
                      <div style="--nth-child: 24;"></div>
                      <div style="--nth-child: 25;"></div>
                      <div style="--nth-child: 26;"></div>
                      <div style="--nth-child: 27;"></div>
                      <div style="--nth-child: 28;"></div>
                      <div style="--nth-child: 29;"></div>
                      <div style="--nth-child: 30;"></div>
                      <div style="--nth-child: 31;"></div>
                      <div style="--nth-child: 32;"></div>
                      <div style="--nth-child: 33;"></div>
                      </div>
                      <div class="dna" style="left: 15%;">
                        <div style="--nth-child: 1;"></div>
                      <div style="--nth-child: 2;"></div>
                      <div style="--nth-child: 3;"></div>
                      <div style="--nth-child: 4;"></div>
                      <div style="--nth-child: 5;"></div>
                      <div style="--nth-child: 6;"></div>
                      <div style="--nth-child: 7;"></div>
                      <div style="--nth-child: 8;"></div>
                      <div style="--nth-child: 9;"></div>
                      <div style="--nth-child: 10;"></div>
                      <div style="--nth-child: 11;"></div>
                      <div style="--nth-child: 12;"></div>
                      <div style="--nth-child: 13;"></div>
                      <div style="--nth-child: 14;"></div>
                      <div style="--nth-child: 15;"></div>
                      <div style="--nth-child: 16;"></div>
                      <div style="--nth-child: 17;"></div>
                      <div style="--nth-child: 18;"></div>
                      <div style="--nth-child: 19;"></div>
                      <div style="--nth-child: 20;"></div>
                      <div style="--nth-child: 21;"></div>
                      <div style="--nth-child: 22;"></div>
                      <div style="--nth-child: 23;"></div>
                      <div style="--nth-child: 24;"></div>
                      <div style="--nth-child: 25;"></div>
                      <div style="--nth-child: 26;"></div>
                      <div style="--nth-child: 27;"></div>
                      <div style="--nth-child: 28;"></div>
                      <div style="--nth-child: 29;"></div>
                      <div style="--nth-child: 30;"></div>
                      <div style="--nth-child: 31;"></div>
                      <div style="--nth-child: 32;"></div>
                      <div style="--nth-child: 33;"></div>
                      </div>
                      <div class="dna" style="left: 42%;">
                        <div style="--nth-child: 1;"></div>
                        <div style="--nth-child: 2;"></div>
                        <div style="--nth-child: 3;"></div>
                        <div style="--nth-child: 4;"></div>
                        <div style="--nth-child: 5;"></div>
                        <div style="--nth-child: 6;"></div>
                        <div style="--nth-child: 7;"></div>
                        <div style="--nth-child: 8;"></div>
                        <div style="--nth-child: 9;"></div>
                        <div style="--nth-child: 10;"></div>
                        <div style="--nth-child: 11;"></div>
                        <div style="--nth-child: 12;"></div>
                        <div style="--nth-child: 13;"></div>
                        <div style="--nth-child: 14;"></div>
                        <div style="--nth-child: 15;"></div>
                        <div style="--nth-child: 16;"></div>
                        <div style="--nth-child: 17;"></div>
                        <div style="--nth-child: 18;"></div>
                        <div style="--nth-child: 19;"></div>
                        <div style="--nth-child: 20;"></div>
                        <div style="--nth-child: 21;"></div>
                        <div style="--nth-child: 22;"></div>
                        <div style="--nth-child: 23;"></div>
                        <div style="--nth-child: 24;"></div>
                        <div style="--nth-child: 25;"></div>
                        <div style="--nth-child: 26;"></div>
                        <div style="--nth-child: 27;"></div>
                        <div style="--nth-child: 28;"></div>
                        <div style="--nth-child: 29;"></div>
                        <div style="--nth-child: 30;"></div>
                        <div style="--nth-child: 31;"></div>
                        <div style="--nth-child: 32;"></div>
                        <div style="--nth-child: 33;"></div>
                      </div>
                </div>
            </div>

            <div class="col-md">
                <div class="NFTSTICKER">
                    <img alt="DNA" width="1920" height= "1080" loading="lazy" class="img-fluid" src="/assets/images/home/GROOVY_LIBRARY/SLIDE2/STICKER_DNA.webp">
                </div>
                <div class="slideContent2">
                    <p class="text">  
                        <span>•</span> Empowering the cannabis industry <br class="md-none">
                        with blockchain technology.
                        <br>
                        <span>•</span> Creating a fair and transparent <br class="md-none">
                        marketplace for genetic innovation.<br>
    
                        <span>•</span> Building a community of breeders,<br class="md-none">
                         cultivators, and consumers.
                    </p>
                    <p class="text">
    <b>Contact us to learn more about how<br class="md-none">
        to participate!</b>
                    </p>
                    <button class="btn-slider CONTACT"  (click)="navigateToComponent(9,0)">
                        <div class="btnText">
                          <span>C</span>
                          <span>o</span>
                          <span>n</span>
                          <span>t</span>
                          <span>a</span>
                          <span>c</span>
                          <span>t</span>
                          <span class="blank"></span>
                          <span>u</span>
                          <span>s</span>

                        </div>
                        <div class="star top-left"></div>
                        <div class="star bottom-right"></div> 
                      </button>
                </div>
               
            </div>

        </div>
        <button class="backButton2" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
    </div>    
</div>