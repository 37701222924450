import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.css']
})
export class BackToTopComponent {
  @Output() reset = new EventEmitter<void>();

  scrollToTop(): void {
    this.reset.emit()
  }
}
