<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" >  
    <div class="BG1">
        <div class="row">
            <div class="col-md-6">
                <div class="slideContent1">
                    <p class="text">
                       <b>Seamless Rewards:</b> Manage and redeem<br class="md-none"> 
                       your GROOVY points within your wallet. <br class="md-none">
                       Your rewards are always accessible, no <br class="md-none">
                       matter where you are in the digital world.
                    </p>
                    <p class="text mt-4">
                        <b>NFT Management:</b> Easily view, manage, and<br class="md-none">
                        trade your GROOVY NFTs, representing<br class="md-none">
                        unique strains, digital art, or other valuable<br class="md-none">
                         collectibles.
                    </p>
                    <p class="text mt-4">
                        <b>Exclusive Access:</b> Gain early access to new <br class="md-none">
                        product drops, special promotions, and <br class="md-none">
                        other exclusive benefits only available to <br class="md-none">
                        Web3 users.
                    </p> 
                </div>
            </div>
            <div class="col-md-6">
                <div class="COINS2">
                    <img width="1920" height= "1080" alt="COINS2" loading="lazy" class="img-fluid" src="/assets/images/home/WEB3/SLIDE1/COINS2.webp">
                </div>
                <div class="WALLET">
                    <img width="1920" height= "1080" alt="WALLET" loading="lazy" class="img-fluid" src="/assets/images/home/WEB3/SLIDE1/WALLET.webp">
                </div>
            
            </div>

        </div>
            <button class="backButton" (click)="handleSlideDesactivation(0)">
                BACK
              </button>
          </div>

    <div class="BG">
        <div class="row">
            <div class="col-md">
                <div class="slideContent"> 
                    <div class="title">
                        <img width="1920" height= "1080" alt="TITLE" loading="lazy" class="img-fluid" src="/assets/images/home/WEB3/THE_GROOVY_WEB3.webp">
                    </div>

                    <p class="text-white text">
                        The GROOVY Web3 <b>Connector is your<br class="md-none">
                        all-access pass to the exciting world of<br class="md-none">
                        GROOVY's blockchain-powered cannabis <br class="md-none">
                         marketplace.</b> It seamlessly bridges the gap <br class="md-none">
                         between your favorite Web3 wallet and the <br class="md-none">
                         GROOVY ecosystem,<b> unlocking a wealth<br class="md-none">
                        of features and benefits.</b>
                    </p>

                    <div class="buttonsContainer">
                        <button class="btn-slider"  (click)="handleSlideActivation(1)">
                          <div class="btnText">
                            <span>M</span>
                            <span>a</span>
                            <span>n</span>
                            <span>a</span>
                            <span>g</span>
                            <span>e</span>
                          </div>
                          <div class="star top-right"></div>
                          <div class="star bottom-left"></div> 
                        </button>
                        <button class="btn-slider"  (click)="handleSlideActivation(2)">
                          <div class="btnText">
                            <span>S</span>
                            <span>e</span>
                            <span>c</span>
                            <span>u</span>
                            <span>r</span>
                            <span>e</span>
                          </div>
                          <div class="star top-left"></div>
                          <div class="star bottom-right"></div> 
                        </button>
                      </div>

                      <div class="stickers">
                        <button class="PLAYSTORE" aria-label="playstore">
                            <img alt="googlePlay" width="1920" height= "1080" loading="lazy" class="img-fluid" src="/assets/images/hero/Googleplay.webp">
                        </button>
                        <button class="APPSTORE" aria-label="appstore">
                            <img alt="playstore" width="1920" height= "1080" loading="lazy" class="img-fluid" src="/assets/images/hero/AppStore.webp">
                        </button>
                      </div>
                </div>
            </div>
            <div class="col-md">
                <div class="coinsElement">
                    <img width="1920" height= "1080" alt="COINS" loading="lazy" class="img-fluid" src="/assets/images/home/WEB3/COINS.webp">
                </div>
            </div>
        </div>
        
    </div>
    
    <div class="BG2">
        <div class="row">
            <div class="col-md">
                <div class="ELEMENT2">
                    <img width="1920" height= "1080" alt="ELEMEN_SECURITY" loading="lazy" class="img-fluid" src="/assets/images/home/WEB3/SLIDE2/ELEMENT_SECURITY.webp"> 
                </div>
            </div>
            <div class="col-md">   
                    <div class="slideContent2">
                        <p class="text">
                           <b>Convenience:</b> Simplify your GROOVY experience <br class="md-none"> 
                           by managing everything you need within your <br class="md-none">
                           existing Web3 wallet.
                        </p>
                        <p class="text mt-4">
                            <b>Security:</b> Leverage the robust security of blockchain <br class="md-none">
                            technology to protect your digital assets and <br class="md-none">
                            personal information.
                        </p>
                        <p class="text mt-4">
                            <b>Community</b> Connect with like-minded cannabis  <br class="md-none">
                            enthusiasts and professionals in the GROOVY<br class="md-none">
                            ecosystem, all while enjoying the benefits of  <br class="md-none">
                            decentralized technology.
                        </p> 
                    </div>
                </div>
                <div class="CASH">
                    <img width="1920" height= "1080" alt="CASH" loading="lazy" class="img-fluid" src="/assets/images/home/WEB3/SLIDE2/CASH.webp"> 

                </div>
        </div>
        <button class="backButton2" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
    
    </div>

    </div>