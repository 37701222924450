<div class="turnTableCont" (mouseenter)="activateSound()" (mouseleave)="deactivateSound()">
    <div class="stick">
        <img width="1920" height= "1080"  alt="stick" class="img-fluid" src="/assets/images/hero/turntable/stick.svg" loading="lazy">
    </div>
    <div class="disk">
        <img width="1920" height= "1080" alt="disk"  class="img-fluid" src="/assets/images/hero/turntable/disk.svg" loading="lazy">
    </div>
    <div class="turnTable">
        <span class="eyes"></span>
        <span class="eyes"></span>
    </div>
    <div class="bgWhite"></div>

</div>