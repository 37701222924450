import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroComponent } from './hero.component';
import { AngelComponent } from './angel/angel.component';
import { PhoneComponent } from './phone/phone.component';
import { PencilComponent } from './pencil/pencil.component';
import { RollerComponent } from './roller/roller.component';
import { TurntableComponent } from './turntable/turntable.component';
import { CanComponent } from './can/can.component';
import { WomanComponent } from './woman/woman.component';
import { BusComponent } from './bus/bus.component';
import { MachineComponent } from './machine/machine.component';
import { RobotComponent } from './robot/robot.component';
import { DynamicScaleDirective } from 'src/app/UI/directives/dynamicscale.directive';
import { TvComponent } from './tv/tv.component';
import { ChairComponent } from './chair/chair.component';
import { PapersComponent } from './papers/papers.component';
import { EyesComponent } from './eyes/eyes.component';



@NgModule({
  declarations: [
      DynamicScaleDirective,
    HeroComponent,
    AngelComponent,
    PhoneComponent,
    PencilComponent,
    RollerComponent,
    TurntableComponent,
    CanComponent,
    WomanComponent,
    BusComponent,
    MachineComponent,
    RobotComponent,
    TvComponent,
    ChairComponent,
    PapersComponent,
    EyesComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    HeroComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HeroModule { }
