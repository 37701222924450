import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-investors-relations',
  templateUrl: './investors-relations.component.html',
  styleUrls: ['./investors-relations.component.css']
})
export class InvestorsRelationsComponent implements OnInit {
  openLinkInNewWindow(url: string): void {
    window.open(url, '_blank');
  }
  slideIsActive: number = 0;
  @Output() navigate = new EventEmitter<number>();

  navigateToComponent(index: number) {
    this.navigate.emit(index);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
