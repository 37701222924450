<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" >  
<div class="BG1">
  <div class="row">
    <div class="col-md">
      <div class="rocket">
        <img width="1920" height= "1080" alt="rocket" loading="lazy" class="img-fluid" src="/assets/images/home/OUR_TECH/BLOCKCHAIN/ROCKET.webp">
        </div>

    </div>
    <div class="col-md">
      <div class="slideContent1">
        <div class="OUR_TECH_CARD">
          <p>
            BLOCKCHAIN: Our secure, immutable <br>
            blockchain ledger creates a tamper-proof<br>
             record of product origins, genetic data, and<br>
            transactions, ensuring the highest levels of<br>
            authenticity and trust.
          </p>
        </div>

        <div class="OUR_TECH_CARD">
          <p>
            <span>NON-FUNGIBLE TOKENS (NFTS): Unique digital</span><br>
            identifiers (NFTs) tied to each Groovy Tag<br>
            create unbreakable links between physical<br>
            products and their digital identities,<br>
            guaranteeing transparency and provenance.
          </p>
        </div>
      </div>
        
    </div>
  </div>
  <button class="backButton" (click)="handleSlideDesactivation(0)">
    BACK
  </button>
</div>
  
<div class="BG"  >
    <div class="row">
        <div class="col-md-6">  
            <div class="title">
                <img width="1920" height= "1080" loading="lazy" src="assets/images/home/OUR_TECH/OUR_TECH.webp" alt="Movable Image" class="img-fluid">
              </div>
              <div class="slideText">
                <p class="text"> GROOVY isn't just about today's <br>
                solutions; we're building the foundation <br>
                for tomorrow's cannabis industry. By <br>
                embracing the technologies of the <br>
                Fourth Industrial Revolution (4IR), we're <br>
                enhancing transparency, empowering <br>
                consumers, and revolutionizing how the <br>
                industry operates.</p>

                <div class="buttonsContainer">
                    <button class="btn-slider"  (click)="handleSlideActivation(1)">
                      <div class="btnText">
                        <span>B</span>
                        <span>l</span>
                        <span>o</span>
                        <span>c</span>
                        <span>k</span>
                        <span>c</span>
                        <span>h</span>
                        <span>a</span>
                        <span>i</span>
                        <span>n</span>
                      </div>
                        <div class="star top-right"></div>
                        <div class="star bottom-left"></div>
                    </button>
                      <button class="btn-slider"  (click)="handleSlideActivation(2)">
                        <div class="btnText">
                          <span>A</span>
                          <span>I</span>
                          </div>
                        <div class="star top-left"></div>
                        <div class="star bottom-right"></div>  
                    </button>
                </div>
                <p class="text2">
                  We believe in blending the rich heritage of cannabis <br>
                  with the cutting-edge tools of the 4IR, for a safer, <br>
                  more transparent, and more efficient cannabis industry <br>
                  for everyone.
                </p>
              </div>

        </div>
        <div class="col-md">
            <div class="ROBOT">
                <img width="1920" height= "1080" alt="robot" loading="lazy" class="img-fluid" src="assets/images/home/OUR_TECH/ROBOT2.webp"> 
            </div>
        </div>
    </div>  
   <div class="orangeBar">
   </div>

</div>

<div class="BG2">
  <div class="row">
    <div class="col-md">
      <div class="HAND">
        <img width="1920" height= "1080" alt="hand" loading="lazy" class="img-fluid" src="/assets/images/home/OUR_TECH/AI/HAND.webp">
      </div>
    </div>
    <div class="col-md">
      <div class="slideContent2">
        <p class="text text-white text-center">
          ARTIFICIAL INTELLIGENCE (AI): Our AI-powered <br>
          analytics engine turns raw data into <br>
          actionable insights. We uncover market<br>
          trends, predict demand, and personalize<br>
          consumer experiences, driving growth and<br>
           efficiency throughout the cannabis<br>
          ecosystem.
        </p>

        <p class="text text-white text-center">
          INTERNET OF THINGS (IOT): We're exploring the<br>
          integration of IoT sensors for real-time<br>
           monitoring of cultivation conditions and<br>
           supply chain tracking, ensuring optimal<br>
          product quality and compliance.
        </p>
      </div>
    </div>
  </div>
  <button class="backButton2" (click)="handleSlideDesactivation(0)">
    BACK
  </button>
</div>
  
</div>
