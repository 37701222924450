<div class="ComponentCont" [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }">
    <div class="BG1">
        <div class="movingTextCont">
            <div class="movingText">
                <h2>NEWS</h2>
                <h2>NEWS</h2>
                <h2>NEWS</h2>
                <h2>NEWS</h2>
            </div>
            </div>
            <div class="slideContent1">
                <div class="CARD_OTC">
                    <div class="CARD_TEXT">
                        <p><b>EIN PRESSWIRE</b></p>
                        <p>GROOVY NEWS Press <br>Releases.</p>
                    </div>
                    <div class="CARD_IMG">
                  <img width="1920" height= "1080" alt="CARD" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE1/CARD_1.webp">
                    </div>
                    <div class="CARD_BUTTON" (click)="openLinkInNewWindow('http://www.einpresswire.com/newsroom/groovy_news/')">
                        <img width="1920" height= "1080" alt="CARD_BUTTON" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE1/READ_MORE.webp">
                    </div>
                </div>

                <div class="CARD_OTC">
                    <div class="CARD_TEXT">
                        <p><b>X News</b></p>
                        <p>GROOVY Company, Inc.</p>
                    </div>
                    <div class="CARD_IMG">
                  <img width="1920" height= "1080" alt="CARD" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE1/CARD_2.webp">
                    </div>
                    <div class="CARD_BUTTON" (click)="openLinkInNewWindow('https://x.com/i/communities/1749142706410934470')">
                        <img width="1920" height= "1080" alt="CARD_BUTTON" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE1/READ_MORE.webp">
                    </div>
                </div>

                <div class="CARD_OTC">
                    <div class="CARD_TEXT">
                        <p><b>Reddit</b></p>
                        <p>r/GROOVYCompany</p>
                    </div>
                    <div class="CARD_IMG">
                  <img width="1920" height= "1080" alt="CARD" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE1/CARD_3.webp">
                    </div>
                    <div class="CARD_BUTTON" (click)="openLinkInNewWindow('https://www.reddit.com/r/GROOVYCompany/')">
                        <img width="1920" height= "1080" alt="CARD_BUTTON" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE1/READ_MORE.webp">
                    </div>
                </div>
            </div>
        <button class="backButton" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
    </div>
    <div class="BG">
        <div class="row">
            <div class="col-md-6">
                <div class="title">
                    <img width="1920" height= "1080" alt="TITLE" loading="lazy" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS_RELATIONS2.webp" alt="Movable Image"
                        class="img-fluid">
                </div>
                <div class="slideContent">
                    <p class="text">
                        The global legal cannabis market is <br class="md-none">
                        projected to reach $70.6 billion by 2028, and <br class="md-none">
                        Groovy is positioned to capture a significant share<br class="md-none">
                        of this growing market, by creating a powerful ne-<br class="md-none">
                        twork effect that drives <br class="md-none">
                        exponential growth and establishes the<br class="md-none">
                        platform as the go-to destination for all things <br class="md-none">
                        cannabis.

                    </p>

                    <p class="text mt-4">
                        As more users join and interact with the ecosys-<br class="md-none">
                        tem, the value proposition for both individuals<br class="md-none">
                        and businesses increases, creating a <br class="md-none">
                        self-sustaining cycle of growth and innovation.
                    </p>

                   <div class="buttonsContainer">
          <button class="btn-slider" id="exclusive" (click)="handleSlideActivation(1)">
            <div class="btnText">
              <span>P</span>
              <span>r</span>
              <span>e</span>
              <span>s</span>
              <span>s</span>
            </div>
            <div class="btnText" id="second" >
              <span>r</span>
              <span>e</span>
              <span>l</span>
              <span>e</span>
              <span>a</span>
              <span>s</span>
              <span>e</span>
            </div>
            <div class="star top-right"></div>
            <div class="star bottom-left"></div> 
          </button>
          <button class="btn-slider"  id="howToEarn" (click)="handleSlideActivation(2)">
            <div class="btnText">
              <span>O</span>
              <span>T</span>
              <span>C</span>
            </div>
            <div class="btnText" id="second" >
              <span>m</span>
              <span>a</span>
              <span>r</span>
              <span>k</span>
              <span>e</span>
              <span>t</span>
              <span>s</span>
            </div>
            <div class="star top-left"></div>
            <div class="star bottom-right"></div> 
          </button>
        </div>
                </div>
            </div>
            <div class="col-md">
                <div class="elementsCont">

                    <div class="movingTextCont">
                        <div class="movingText">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                        </div>
                        <div class="movingText2">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                        </div>
                        <div class="movingText">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                        </div>
                        <div class="movingText2">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                        </div>
                        <div class="movingText">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                        </div>
                        <div class="movingText2">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                        </div>
                        <div class="movingText">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                            <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/INVESTORS.webp">
                        </div>
                    </div>
                    <div class="STICKERS">
                        <img width="1920" height= "1080" alt="STICKERS" loading="lazy" class="img-fluid"
                            src="assets/images/home/INVESTORS_RELATIONS/INVESTORS_RELATIONS_STICKERS2.webp">
                    </div>
                    <div class="MACHINE">
                        <img width="1920" height= "1080" alt="MACHINE" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/MACHINE3.webp">
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="BG2">

        <div class="OTC_TITLE">
            <img width="1920" height= "1080" alt="TITLE" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE2/OTC_MARKETS.webp">
            
        </div>
        <div class="slideContent2">
            <div class="CARD_OTC">
                <div class="CARD_TEXT">
                    <p><b>OTC Markets</b></p>
                    <p>GROO</p>
                </div>
                <div class="CARD_IMG">
              <img width="1920" height= "1080" alt="CARD" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE2/CARD_1.webp">
                </div>
                <div class="CARD_BUTTON" (click)="openLinkInNewWindow('https://www.otcmarkets.com/stock/SANP/overview')">
                    <img width="1920" height= "1080" alt="CARD_BUTTON" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE2/READ_MORE.webp">
                </div>
            </div>

            <div class="CARD_OTC">
                <div class="CARD_TEXT">
                    <p><b>EDGAR search tools</b></p>
                    <p>Groovy Company, Inc.</p>
                </div>
                <div class="CARD_IMG">
              <img width="1920" height= "1080" alt="CARD" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE2/CARD_2.webp">
                </div>
                <div class="CARD_BUTTON"  (click)="openLinkInNewWindow('https://www.sec.gov/edgar/browse/?CIK=1499275&owner=exclude')">
                    <img width="1920" height= "1080" alt="CARD_BUTTON" loading="lazy" class="img-fluid" src="assets/images/home/INVESTORS_RELATIONS/SLIDE2/READ_MORE.webp">
                </div>
            </div>
        </div>
        <button class="backButton2" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
    </div>
</div>