<div class="tvPositioner">
  <div class="tvContainer" (mouseenter)="activateSound()" (mouseleave)="deactivateSound()">
    <div class="antenaPositioner">
      <span></span>
      <span></span>
    </div>
    <span class="tvButton1"></span>
    <span class="tvButton2"></span>

    <!-- <button class="videoButton" [style.opacity]="buttonOpacity" (click)="togglePlayPause()">
      <span class="videoIcon" [ngClass]="stateResolution()"></span>
    </button> -->

  </div>
  <div class="video">
    <video #videoPlayer [muted]="true" preload="auto" class="videoGroovy" autoplay playsinline disablePictureInPicture loop>
      <source src="assets/videos/SplashV8-min.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>

  </div>
</div>