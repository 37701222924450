import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  openLinkInNewWindow(url: string): void {
    window.open(url, '_blank');
  }
  openMailInNewWindow(email: string, subject?: string, body?: string): void {
    let mailtoLink = `mailto:${email}`;
    
    const params = [];
    if (subject) {
      params.push(`subject=${encodeURIComponent(subject)}`);
    }
    if (body) {
      params.push(`body=${encodeURIComponent(body)}`);
    }
  
    if (params.length > 0) {
      mailtoLink += `?${params.join('&')}`;
    }
  
    window.open(mailtoLink, '_blank');
  }
}
