import { Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-groovy-library',
  templateUrl: './groovy-library.component.html',
  styleUrls: ['./groovy-library.component.css']
})
export class GroovyLibraryComponent {
  slideIsActive: number = 0;
  @Output() navigate = new EventEmitter<number>();
  @Output() slideContact = new EventEmitter<number>();


  navigateToComponent(index: number, slide?: number) {
    this.navigate.emit(index);
    this.slideContact.emit(slide);
  }
  
  handleSlideActivation(number: number) {
    this.slideIsActive = number;
  }

  handleSlideDesactivation(number: number) {
    this.slideIsActive = number;
  }
}
