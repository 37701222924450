import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SoundService } from 'src/app/infraestructure/driven-adapter/services/sound.service';

@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.css']
})
export class TvComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  public playState: 'play' | 'pause' | 'repeat' = 'pause'; 
  public buttonOpacity: number = 1;
  soundActive: boolean = false;
  hoverSound: HTMLAudioElement | any;

  constructor( private soundService: SoundService) {
  }
  
  mute: boolean = false;
  private subscription!: Subscription;


  ngOnInit() {
    this.subscription = this.soundService.mute$.subscribe(mute => {
      this.mute = mute;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private loadAudio() {
    if (!this.hoverSound) {
      this.hoverSound = new Audio('assets/sounds/tv.mp3');
      this.hoverSound.loop = true;
    }
  }
  activateSound(): any {
    if (this.mute === true) {
      return null;
    } else {
      this.loadAudio();
      this.soundActive = true;
      this.hoverSound.play();
    }
  }

  deactivateSound() {
    if (this.hoverSound) {
      setTimeout(() => {
        this.hoverSound.currentTime = 0;
        this.hoverSound.pause();
        this.soundActive = false;
      }, 600);
    }
  }

  togglePlayPause(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    if (video.paused || video.ended) {
      if (video.ended) {
        this.repeatVideo();
      } else {
        video.play();
        this.playState = 'play';
        this.buttonOpacity = 0.25;
      }
    } else {
      video.pause();
      this.playState = 'pause';
      this.buttonOpacity = 1;
    }
  }
  ngAfterViewInit(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.addEventListener('ended', () => {
      this.playState = 'repeat';
    });
  }
  repeatVideo(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.currentTime = 0; // Reiniciar la reproducción desde el principio
    video.play(); // Iniciar la reproducción
    this.playState = 'play'; // Actualizar el estado de reproducción
  }

  stateResolution(){
    if(this.playState === 'play'){
      return 'pause'
    }else if(this.playState === 'pause'){
      return 'play'
    }else if(this.playState === 'repeat'){
      return 'repeat'
    }return null
   }
}
