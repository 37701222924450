<!-- <app-documents [pdfUrl]="pdfUrl2"></app-documents> -->
<span class="backToHomeCont" [routerLink]="['/']">
    <div class="backToHomeBG">
     <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/Peace.webp">
    </div>
    <div class="backToHome">
       <img width="1920" height= "1080" loading="lazy" class="img-fluid" src="assets/images/BacktoHome.webp">
      </div>
    </span>
<div style="width: 100%; height: 100vh;" *ngIf="pdfUrl2 === 'affiliates_&_partners'">
    <iframe src="https://zfrmz.com/BkNfzdDX50HTloRbGUx6" width="100%" height="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen style="border: 0px; display: block; background-color: #FFFFFF"></iframe>
</div>
