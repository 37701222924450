import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from '../../modules/auth/auth.component';
import { environment } from 'src/environments/environment';
import { ProfileComponent } from '../../modules/profile/profile.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { HeroModule } from '../../modules/home/hero/hero.module';
import { HomeModule } from '../../modules/home/home.module';
import { SplashscreenComponent } from '../splashscreen/splashscreen.component';
@NgModule({
  declarations: [
    DefaultComponent,
    ProfileComponent,
    SplashscreenComponent
  ],
  imports: [
    VgBufferingModule,
    VgOverlayPlayModule ,
    VgCoreModule,
    VgControlsModule,
    FormsModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule,
    HeroModule,
    HomeModule,
    NgOptimizedImage
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class DefaultModule { }
