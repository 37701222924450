<div class="ComponentCont"  [ngClass]="{ 'slideLeft': slideIsActive === 1, 'slideRight': slideIsActive === 2 }" >  
    <div class="BG1">
        <div class="row">
            <div class="col-md">
                <div class="slideTitle1">
                    <img width="1920" height= "1080" alt="TITLE" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/SLIDE1/SLIDE_1_TITLE.webp"> 
                </div>
                <div class="textSlide1">
                    <p class="text">
                        <b class="pink">DATA-AS-A-SERVICE (DAAS):</b>  We offer<br>
                        subscription-based access to our<br>
                        comprehensive data analytics platform,<br>
                        giving businesses a competitive edge<br>
                        through real-time market intelligence.
                    </p>
                    <p class="text">
                        <b class="pink">TARGETED ADVERTISING:</b>  Leveraging consumer<br>
                        behavior data, we deliver highly relevant ads<br>
                         to users within the GROOVY app, driving<br>
                         engagement and sales for participating<br>
                          brands and retailers.
                    </p>
                </div>
                
            </div>
            <div class="col-md-6">
                <div class="SLIDE_IMG">
                    <img width="1920" height= "1080" alt="ELEM" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/SLIDE1/ELEMENT_IMG1.webp"> 
                </div>
            </div>
        </div>

        <button class="backButton" (click)="handleSlideDesactivation(0)">
            BACK
          </button>
    </div>
<div class="BG"  >
    <div class="row">
        <div class="road">
            <img width="1920" height= "1080" alt="ROAD" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/ROAD2.webp"> 
        </div>
        <div class="col-md">
            <div class="elementsCont">

            <div class="movingTextCont" >
                <div class="movingText">
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                </div>
                <div class="movingText2">
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                </div>
                <div class="movingText">
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                </div>
                <div class="movingText2">
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                </div>
                <div class="movingText">
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                    <img width="1920" height= "1080" alt="movingText" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC_TEXT.webp"> 
                </div>
            </div>
                <div class="STICKERS">
                <img width="1920" height= "1080" alt="STICKERS" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTICS_STICKERS.webp"> 
            </div>
            <div class="BUS">
                <img width="1920" height= "1080" alt="BUS" loading="lazy" class="img-fluid" src="assets/images/home/DATA_ANALYTICS/BUS_2.webp"> 
            </div>
        </div>
        </div>
        <div class="col-md-7">  
            <div class="title">
                <img width="1920" height= "1080" loading="lazy" src="assets/images/home/DATA_ANALYTICS/DATA_ANALYTIC.webp" alt="Title" class="img-fluid">
              </div>
                <div class="slideContent">
                    <p class="text">
                        GROOVY isn't just about authentication; it's <br>
                        about harnessing the power of data to<br>
                        transform the cannabis industry. Our <br>
                        advanced analytics engine turns raw data <br>
                        into actionable insights, unlocking new <br>
                        revenue streams for businesses and <br>
                        enriching the overall cannabis ecosystem.
                    </p>
                </div>
                <div class="slideFooter">
                    <div class="buttonsContainer">
                        <button class="btn-slider"  (click)="handleSlideActivation(1)">
                            <div class="btnText">
                                <span>D</span>
                                <span>a</span>
                                <span>a</span>
                                <span>s</span>
                              </div>
                            <div class="star top-right"></div>
                            <div class="star bottom-left"></div>
                        </button>
                          <button class="btn-slider"  (click)="handleSlideActivation(2)">
                            <div class="btnText">
                                <span>D</span>
                                <span>a</span>
                                <span>t</span>
                                <span>a</span>
                              </div>
                            <div class="star top-left"></div>
                            <div class="star bottom-right"></div>  
                        </button>
                    </div>
                    <p class="text text-white">
                        With GROOVY, data isn't just bits and<br>
                         bytes – it's a powerful tool for growth. 
                    </p>
                </div>
         
        </div>
     
    </div>  
</div>
<div class="BG2">
    <div class="row">
        <div class="col-md-7">
            <div class="ELEMENT_GRAPH">
                <img width="1920" height= "1080" alt="BAR_GRAPH" loading="lazy" class="img-fluid" src="/assets/images/home/DATA_ANALYTICS/SLIDE2/BAR_GRAPH.webp">

            </div>
            <div class="ELEMENT_IMG2"> 
                <img width="1920" height= "1080" alt="WOMAN" loading="lazy" class="img-fluid" src="/assets/images/home/DATA_ANALYTICS/SLIDE2/WOMAN.webp">
            </div>  
        </div>
        <div class="col-md">
            <div class="slideContent2">
               
                <p class="text">
                    GROWERS: Identify trending strains, optimize<br>
                     cultivation strategies, and make informed <br>
                     decisions about new product development.
                </p>
                <p class="text">
                    MANUFACTURERS: Track product<br>
                     performance, understand regional<br>
                    preferences, and tailor marketing<br>
                    campaigns to specific audiences.
                </p>
                <p class="text">
                    RETAILERS: Forecast demand, optimize<br>
                     inventory, and personalize promotions to<br>
                      increase customer loyalty and drive sales.
                </p>
                <p class="text">
                    INDUSTRY: Promote transparency, combat<br>
                    counterfeits, and drive innovation through<br>
                    data-backed decision-making.
                </p>
            </div>
        </div>
    </div>
    <button class="backButton2" (click)="handleSlideDesactivation(0)">
        BACK
      </button>
</div>
</div>