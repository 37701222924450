import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeroModule } from './hero/hero.module';
import { AffiliatesAndPartnersComponent } from './affiliates-and-partners/affiliates-and-partners.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SharedModule } from '../../shared/shared.module';
import { RewardsMarketplaceComponent } from './rewards-marketplace/rewards-marketplace.component';
import { GroovyTagsComponent } from './groovy-tags/groovy-tags.component';
import { MailtoComponent } from './mailto/mailto.component';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { OurTechComponent } from './our-tech/our-tech.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { GroovyP2pComponent } from './groovy-p2p/groovy-p2p.component';
import { InvestorsRelationsComponent } from './investors-relations/investors-relations.component';
import { GroovyExplorerComponent } from './groovy-explorer/groovy-explorer.component';
import { GroovyRewardsComponent } from './groovy-rewards/groovy-rewards.component';
import { ContactUsModule } from './contact-us/contact-us.module';
import { WEB3Component } from './web3/web3.component';
import { GroovyLibraryComponent } from './groovy-library/groovy-library.component';
import { GroovyP2pModule } from './groovy-p2p/groovy-p2p.module';
@NgModule({
  declarations: [
    AffiliatesAndPartnersComponent,
    AboutUsComponent,
    RewardsMarketplaceComponent,
    GroovyTagsComponent,
    MailtoComponent,
    DataAnalyticsComponent,
    OurTechComponent,
    SocialMediaComponent,
    InvestorsRelationsComponent,
    GroovyExplorerComponent,
    GroovyRewardsComponent,
    WEB3Component,
    GroovyLibraryComponent,
  ],
  imports: [
    NgOptimizedImage,
    CommonModule,
    HeroModule,
    SharedModule,
    ContactUsModule,
    GroovyP2pModule,
  ],
  exports: [
     AffiliatesAndPartnersComponent, 
     AboutUsComponent,
     RewardsMarketplaceComponent,
     GroovyTagsComponent,
     MailtoComponent,
     DataAnalyticsComponent,
     OurTechComponent,
     SocialMediaComponent,
     ContactUsComponent,
     GroovyP2pComponent,
     InvestorsRelationsComponent,
     GroovyExplorerComponent,
     GroovyRewardsComponent,
     WEB3Component,
     GroovyLibraryComponent
     ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule { }
